import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Localized, useLocalization } from "@fluent/react";
import { Button } from "@narmi/design_system";
import { ContextForm, useNotificationContext, PasswordTextInput, useFormData, CsrfTokenMiddleware, jsonFromDocument, } from "cerulean";
import { useEffect, useRef } from "react";
import "./authentication.css";
var ChangePasswordForm = function (_a) {
    var form = _a.form, formData = _a.formData, onChange = _a.onChange;
    var l10n = useLocalization().l10n;
    return (_jsxs(ContextForm, { ref: form, data: formData, onChange: onChange, autoComplete: "off", method: "post", children: [_jsx(ContextForm.Field, { required: true, children: _jsx(PasswordTextInput, { name: "new_password1", field: "new_password1", label: l10n.getString("label-new-password") }) }), _jsx(ContextForm.Field, { required: true, children: _jsx(PasswordTextInput, { name: "new_password2", field: "new_password2", label: l10n.getString("label-confirm-new-password") }) }), _jsx(CsrfTokenMiddleware, {}), _jsx(ContextForm.ActionBar, { children: _jsx(ContextForm.Action, { onSubmit: function (_, e) {
                        e.preventDefault();
                        if (form === null || form === void 0 ? void 0 : form.current) {
                            form.current.submit();
                        }
                    }, children: _jsx(Button, { label: l10n.getString("button-submit") }) }) })] }));
};
var ChangePasswordContainer = function () {
    var sendNotification = useNotificationContext().sendNotification;
    var _a = useFormData(), formData = _a.formData, onChange = _a.onChange;
    var l10n = useLocalization().l10n;
    var form = useRef(null);
    var passwordChangeSubmitted = jsonFromDocument("password_change_done", "false");
    var isValidLink = jsonFromDocument("is_valid_link", "false");
    useEffect(function () {
        var formErrors = jsonFromDocument("password_errors", "[]");
        if (formErrors.length) {
            sendNotification({ type: "negative", text: formErrors[0] });
        }
    }, []);
    var header = l10n.getString("heading-invalid-reset-password");
    var subText = (_jsx(Localized, { id: "subheading-invalid-reset-password", elems: {
            resetLink: _jsx(Button, { as: "a", kind: "plain", href: "/password_reset" }),
        }, children: _jsx("span", { children: "The password reset link was invalid, possibly because it has already been used. Please <resetLink>request a new password reset.</resetLink>" }) }));
    if (isValidLink && !passwordChangeSubmitted) {
        header = l10n.getString("heading-reset-password-form");
        subText = _jsx(Localized, { id: "subheading-reset-password-form" });
    }
    else if (passwordChangeSubmitted) {
        header = l10n.getString("heading-reset-password-success");
        subText = _jsx(Localized, { id: "subheading-reset-password-success" });
    }
    return (_jsxs("div", { className: "authentication-container", children: [_jsxs("div", { className: "mobile-web-extra-space", children: [_jsx("h1", { children: header }), subText] }), isValidLink && !passwordChangeSubmitted && (_jsx(ChangePasswordForm, { form: form, formData: formData, onChange: onChange })), passwordChangeSubmitted && (_jsx(ContextForm.ActionBar, { children: _jsx(Button, { label: l10n.getString("label-back-to-login"), onClick: function () { return window.location.assign("/login"); } }) }))] }));
};
export default ChangePasswordContainer;
