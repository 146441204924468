var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var validStyles = ["currency", "percent"];
export var formatNumber = function (input, style) {
    if (style === void 0) { style = "currency"; }
    var number = parseFloat(input);
    var formatterOpts = __assign({ style: style, currencyDisplay: "narrowSymbol", 
        // Range error if maximum and minimum are not both set
        maximumFractionDigits: Number.isInteger(number) ? 0 : 2, minimumFractionDigits: Number.isInteger(number) ? 0 : 2 }, (style === "currency" ? { currency: "USD" } : {}));
    // only allow currency and percentage types for now
    if (!validStyles.includes(style)) {
        throw new Error("formatNumber: invalid style argument. Must be one of: ".concat(JSON.stringify(validStyles)));
    }
    return new Intl.NumberFormat("en-US", formatterOpts).format(number);
};
