import React, { useContext, useRef } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import {
  Button,
  CardCvcTextInput,
  CardNumberTextInput,
  ContextForm,
  getMaxLengthWithoutSpaces,
  ExpirationDateField,
  Row,
  TextInput,
  ZipCodeTextInput,
  validateCardExpiry,
  validateCvc,
} from "cerulean";
import creditCardType from "credit-card-type";
import { OtsTokenError } from "byzantine/src/ApiHttp";
import InstitutionSettingsContext from "../../../../contexts/InstitutionSettingsContext";
import ApiAuthorizenet from "./ApiAuthorizenet";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";

const parseCardType = (cardType) =>
  cardType === "american-express" ? "amex" : cardType;

const validateCardType = (value, acceptedCards, l10n) => {
  const expectedCardNumberLength = getMaxLengthWithoutSpaces(value);
  if (value.length < expectedCardNumberLength) {
    return l10n.getString("loan-paybycard-incorrect-card-number-length-error", {
      expectedCardNumberLength,
    });
  }

  const possibleCardTypes = creditCardType(value);
  if (possibleCardTypes.length > 1 || possibleCardTypes.length === 0) {
    return l10n.getString("loan-paybycard-invalid-card-number-error");
  }

  const cardType = parseCardType(possibleCardTypes[0].type);
  if (!acceptedCards?.includes(cardType)) {
    return l10n.getString("loan-paybycard-unaccepted-card-type-error", {
      cardType: possibleCardTypes[0].niceType,
    });
  }
  return null;
};

const handleCardInfo = async (user, cardData, authData, token, secret) => {
  const nonce = await ApiAuthorizenet.getNonce({ cardData, authData });
  return user.addFundingCard({ nonce, type: "card" }, token, secret);
};

const AddCardForm = ({
  token,
  secret,
  shouldHideAllToasts = false,
  formData,
  setFormData,
  onChange,
  onCancel,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const { l10n } = useLocalization();
  const { currentUser } = useCurrentUser();
  const {
    authorize_dot_net_client_key_business,
    authorize_dot_net_client_key,
    authorize_dot_net_login_id_business,
    authorize_dot_net_login_id,
    dao_fund_card_network,
  } = useContext(InstitutionSettingsContext);

  // using a ref hook here because the ContextForm does not update its validate functions
  // after initiailizing, so this gives us the freshest version of dao_fund_card_network & l10n
  const daoFundCardNetwork = useRef([]);
  const l10nRef = useRef();
  daoFundCardNetwork.current = dao_fund_card_network;
  l10nRef.current = l10n;

  const isBusiness = currentUser.isBusiness();

  const onSubmit = async (callback) => {
    const authorizeDotNetClientKey = isBusiness
      ? authorize_dot_net_client_key_business
      : authorize_dot_net_client_key;
    const authorizeDotNetLoginId = isBusiness
      ? authorize_dot_net_login_id_business
      : authorize_dot_net_login_id;
    const authData = {
      clientKey: authorizeDotNetClientKey || "",
      apiLoginID: authorizeDotNetLoginId || "",
    };

    const cardData = {
      cardNumber: formData?.card_number || "",
      month: formData?.expiration_date?.substring(0, 2) || "",
      year: `20${formData?.expiration_date?.substring(3, 5)}` || "",
      cardCode: formData?.cvc || "",
      zip: formData?.zip || "",
      fullName: formData?.cardholder_name || "",
    };

    let newlyAddedCard;
    try {
      newlyAddedCard = await handleCardInfo(
        currentUser,
        cardData,
        authData,
        token,
        secret
      );
    } catch (err) {
      if (!(err instanceof OtsTokenError)) {
        const message = err instanceof Error ? err.message : err;
        callback(message);
        onError(message);
        return;
      }

      try {
        newlyAddedCard = await handleCardInfo(
          currentUser,
          cardData,
          authData,
          token,
          secret
        );
      } catch (secondErr) {
        const errMessage =
          secondErr instanceof Error ? secondErr.message : secondErr;
        callback(errMessage);
        onError(errMessage);
        return;
      }
    }

    setFormData({});
    callback();
    onSuccess(newlyAddedCard);
  };

  return (
    <ContextForm
      data={formData}
      onChange={onChange}
      shouldHideAllToasts={shouldHideAllToasts}
    >
      <div className="margin--top--s">
        <ContextForm.Field
          required
          validate={(value) =>
            validateCardType(value, daoFundCardNetwork.current, l10nRef.current)
          }
        >
          <CardNumberTextInput
            field="card_number"
            label={l10n.getString("loan-paybycard-card-number-label")}
            autoComplete="one-time-code"
          />
        </ContextForm.Field>
        <ContextForm.Field required>
          <TextInput
            field="cardholder_name"
            label={l10n.getString("loan-paybycard-cardholder-name-label")}
            autoComplete="one-time-code"
          />
        </ContextForm.Field>
        <div
          className="alignChild--center--top"
          style={{ gap: "var(--space-l)" }}
        >
          <ContextForm.Field
            required
            style={{ flex: 1.7 }}
            validate={validateCardExpiry}
          >
            <ExpirationDateField
              field="expiration_date"
              label={l10n.getString("loan-paybycard-card-expiration-label")}
              autoComplete="one-time-code"
            />
          </ContextForm.Field>
          <ContextForm.Field
            required
            style={{ flex: 1 }}
            validate={(value) => {
              validateCvc(
                value,
                l10n.getString("error-required"),
                l10n.getString("error-card-cvc-invalid")
              );
            }}
          >
            <CardCvcTextInput
              field="cvc"
              label={l10n.getString("loan-paybycard-cvc-label")}
              cardNumber={formData?.card_number}
              supportsFourDigitCvc={daoFundCardNetwork.current?.includes(
                "amex"
              )}
              autoComplete="one-time-code"
            />
          </ContextForm.Field>
        </div>
        <ContextForm.Field required>
          <ZipCodeTextInput
            field="zip"
            label={l10n.getString("loan-paybycard-zip-code-label")}
            autoComplete="postal-code"
          />
        </ContextForm.Field>
        <div className="margin--top--xl">
          <Row justifyContent="end" alignItems="center">
            {onCancel && (
              <Row.Item shrink>
                <Button
                  type="button"
                  kind="negative"
                  label={l10n.getString("loan-paybycard-cancel-button")}
                  onClick={onCancel}
                />
              </Row.Item>
            )}
            <Row.Item shrink>
              <ContextForm.Action onSubmit={onSubmit}>
                <Button
                  kind="primary"
                  label={l10n.getString("loan-paybycard-add-card-button")}
                />
              </ContextForm.Action>
            </Row.Item>
          </Row>
        </div>
      </div>
    </ContextForm>
  );
};

AddCardForm.propTypes = {
  token: PropTypes.string,
  secret: PropTypes.string,
  shouldHideAllToasts: PropTypes.bool,
  formData: PropTypes.object,
  setFormData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default AddCardForm;
