var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { entities, modules } from "byzantine";
import Address from "byzantine/src/Address";
import WireRecipient from "byzantine/src/WireRecipient";
import { AccountNumberTextInput, ContextForm, RoutingNumberTextInput, TextInput, useFormData, useNotificationContext, validateRoutingNumber, FormSection, } from "cerulean";
import BankingUSAddressField from "../../../../../../../../components/address/BankingUSAddressField";
import utils from "../../../../../../../../utils";
import DrawerLayout from "../DrawerLayout";
import BackButton from "../../BackButton";
import styles from "./RecipientForm.module.scss";
var RecipientForm = function () {
    var params = useParams();
    var l10n = useLocalization().l10n;
    var _a = useFormData(), formData = _a.formData, onChange = _a.onChange, setFormData = _a.setFormData;
    var sendNotification = useNotificationContext().sendNotification;
    var _b = __read(useState({}), 2), addressErrors = _b[0], setAddressErrors = _b[1];
    var recipientId = params.recipientId;
    var existingWireRecipient = entities.wires.useOneWireRecipient(recipientId);
    var navigate = useNavigate();
    var upsertOne = entities.wires.useWireRecipients().upsertOne;
    var setFieldValue = modules.wireTemplates.upsertTemplateForm.useForm().setFieldValue;
    var goBack = useCallback(function () {
        navigate(-1);
    }, [navigate]);
    var onData = useCallback(function (recipient) {
        upsertOne(recipient);
        /**
         * Temporary workaround:
         * When navigating back, useEffect overrides form fields with data from Redux.
         * This is usually correct but doesn't handle ephemeral data like a newly created
         * and selected wire recipient. To address this, wait 50 milliseconds before setting
         * the new wire recipient to ensure the form updates correctly.
         */
        setTimeout(function () {
            setFieldValue("wireRecipientId", recipient.id, true);
        }, 50);
        goBack();
        return null;
    }, [upsertOne, setFieldValue, goBack]);
    var validateAddress = function () {
        setAddressErrors({});
        var addressFieldErrors = utils.validateAddress(formData);
        if (Object.keys(addressFieldErrors).length !== 0) {
            setAddressErrors(addressFieldErrors);
            return true;
        }
        return "";
    };
    var onCancel = function () {
        setFormData({});
        setAddressErrors({});
        goBack();
    };
    var onSubmit = function (callback) {
        setAddressErrors({});
        if (validateAddress()) {
            return;
        }
        WireRecipient.create({
            name: formData.name,
            address: new Address({
                street_address: formData.street_address,
                street_address_2: formData.street_address_2,
                city: formData.city,
                region_code: formData.region_code,
                postal_code: formData.postal_code,
                country_code: Address.COUNTRIES.US,
            }),
            routing_number: formData.routing_number,
            account_number: formData.account_number,
        })
            .then(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], rawRecipient = _b[1];
            sendNotification({
                type: "success",
                text: "Recipient added.",
            });
            onData === null || onData === void 0 ? void 0 : onData(rawRecipient);
            setFormData();
            callback === null || callback === void 0 ? void 0 : callback();
        })
            .catch(function (err) {
            if (err === null || err === void 0 ? void 0 : err.address) {
                setAddressErrors(err.address);
                // fedwire errors can be reported as "non_field_errors"
                if (err.address.non_field_errors) {
                    setAddressErrors({ city: err.address.non_field_errors });
                }
            }
            callback === null || callback === void 0 ? void 0 : callback(err);
        });
    };
    return (_jsx("div", { className: styles.formContainer, children: _jsx(ContextForm, { nativeForm: false, data: formData, onChange: onChange, children: _jsx(DrawerLayout, { onSave: onSubmit, saveLabel: "Add recipient", onCancel: onCancel, isContextForm: true, children: _jsxs("div", { className: styles.container, children: [_jsx(BackButton, { onClick: goBack, className: styles.back }), _jsx("h2", { className: styles.header, children: existingWireRecipient ? "Edit recipient" : "New recipient" }), _jsx("div", { className: "margin--bottom--s", children: _jsx(FormSection, { title: "Recipient Details" }) }), _jsx(ContextForm.Field, { required: true, children: _jsx(TextInput, { field: "name", label: l10n.getString("label-name", null, "Name"), maxLength: 35 }) }), _jsx(BankingUSAddressField, { data: formData || {}, errors: addressErrors, onUpdate: onChange, showTitle: false }), _jsx("div", { className: "margin--bottom--s", children: _jsx(FormSection, { title: "Wire Bank Details" }) }), _jsx("div", { className: "margin--bottom--l", children: _jsx(ContextForm.Field, { required: true, children: _jsx(AccountNumberTextInput, { field: "account_number", label: l10n.getString("labelAccountNumber", null, "Account number") }) }) }), _jsx(ContextForm.Field, { required: true, validate: validateRoutingNumber, children: _jsx(RoutingNumberTextInput, { field: "routing_number", label: l10n.getString("label-routing", null, "Routing number") }) })] }) }) }) }));
};
export default memo(RecipientForm);
