import React, {
  useEffect,
  useState,
  createContext,
  useRef,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import { useNotificationContext } from "../NotificationContext";
import { useLoadingContext } from "../LoadingContext";
import {
  useFormReviewWarningsContext,
  FormReviewWarningsContextProvider,
} from "../FormReviewWarningsContext";
import useData from "./useData";
import Action, { ActionBar } from "./Action";
import ContextFormField from "./Field";
import { nanoid } from "nanoid";
import useIsInDialog from "../useIsInDialog";

export const INLINE_ERROR_MESSAGE =
  "Please correct the errors indicated below to submit.";

// TODO do this better
const isLeafMetadataNode = (node) =>
  ["validate", "required"].every((x) => Object.keys(node).includes(x));

export const doFormValidation = (data, metadata, rootData) => {
  const errors = {};
  Object.keys(metadata).forEach((field) => {
    const value = data[field] || "";
    if (!isLeafMetadataNode(metadata[field])) {
      errors[field] = doFormValidation(value, metadata[field], rootData);
    } else {
      const { required, validate } = metadata[field];
      let errorForField = undefined;
      if (validate) {
        // in case the field validation requires access to other fields
        errorForField = validate(value, data, rootData);
      }
      if (!errorForField && required && [null, undefined, ""].includes(value)) {
        errorForField = "Required";
      }
      errors[field] = errorForField;
    }
  });
  return errors;
};

export const FormContext = createContext();

const FormContextProvider = ({
  children,
  data,
  onChange,
  formID: formIDProp,
  shouldHideAllToasts = false,
  shouldHideInlineToasts = false,
}) => {
  const [formID] = useState(formIDProp || nanoid());
  const { data: metadata, dispatchData: dispatchMetadata } = useData();
  const { dispatchData: dispatchFormData } = useData({
    initialData: data,
  });
  const dispatchWithOnChange = (action) => {
    dispatchFormData(action);
    const update = { [action.field]: action.update };
    onChange(mergeObjects(data, update));
  };

  useEffect(() => {
    dispatchFormData({
      type: "update",
      update: data,
    });
  }, [data]);

  const [isSubmitting, setIsSubmitting] = useState("");
  let { sendNotification, clearNotifications, sendNegativeNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const { handleWarnings } = useFormReviewWarningsContext();

  if (shouldHideAllToasts) {
    sendNotification = () => {};
    clearNotifications = () => {};
  }

  const setFormErrors = (errors) => {
    const update = { ...metadata };
    const setErrors = (m, e) => {
      Object.keys(m).forEach((field) => {
        if (isLeafMetadataNode(m[field])) {
          m[field].error = e[field] || undefined;
        } else setErrors(m[field], e[field] || {});
      });
    };
    setErrors(update, errors);
    dispatchMetadata({
      type: "update",
      update,
    });
  };

  const validateForm = () => {
    const validationErrors = doFormValidation(data, metadata, data);
    setFormErrors(validationErrors);
    let hasError = false;
    const nodeHasError = (node) => {
      Object.values(node).forEach((value) => {
        if (value !== null && typeof value === "object") nodeHasError(value);
        else {
          if (!!value) hasError = true;
        }
      });
    };
    nodeHasError(validationErrors);
    return hasError;
  };

  const onSubmit = (action, event) => {
    clearNotifications();
    event.preventDefault();
    if (isSubmitting) return;
    if (!action.dangerouslyDisableShowLoading) setIsLoading(true);
    if (!action.noValidation) {
      const hasError = validateForm();
      if (hasError) {
        if (!action.dangerouslyDisableShowLoading) setIsLoading(false);
        if (!action.shouldHideInlineToasts && !shouldHideInlineToasts) {
          sendNegativeNotification(INLINE_ERROR_MESSAGE);
        }
        return;
      }
    }
    // define callback for fatal errors
    const handleErrors = (errors) => {
      if (errors) {
        // errors can either be string, list of strings, or an object
        let all;
        let fieldErrors;
        if (typeof errors === "string") {
          // the string would be the global error
          all = errors;
          fieldErrors = {};
        } else if (Array.isArray(errors)) {
          all = errors.join(". ");
          fieldErrors = {};
        } else {
          // treat normally
          ({ __all__: all, ...fieldErrors } = errors);
        }
        if (typeof all === "string" && all.match(/[a-zA-Z0-9]$/)) {
          all += ".";
        }
        all =
          all ||
          "Something went wrong. Please review the error indicated or try again later.";
        sendNotification({
          type: "negative",
          text: all,
        });
        setFormErrors(fieldErrors);
      }
    };
    const submitCallback = (errors) => {
      setIsSubmitting("");
      if (!action.dangerouslyDisableShowLoading) setIsLoading(false);
      // trigger warning handling then error handling, set up as a callback chaing and not as
      // a promise chain as there's potentially a user prompt =(
      return handleWarnings(errors, action, handleErrors);
    };
    setIsSubmitting(action.label);
    action.onSubmit(submitCallback, event);
  };

  return (
    <FormContext.Provider
      value={{
        formID,
        data,
        dispatchData: dispatchWithOnChange,
        metadata,
        dispatchMetadata,
        validateForm,
        onSubmit,
        isSubmitting,
        setIsSubmitting,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormContextProvider.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  onChange: PropTypes.func,
  shouldHideAllToasts: PropTypes.bool,
};

FormContextProvider.defaultProps = {
  data: {},
  onChange: () => {},
};

export const isObjectOrArray = (input) => {
  return input && (typeof input === "object" || Array.isArray(input));
};

export const mergeObjects = (base, override) => {
  if (isObjectOrArray(base) && isObjectOrArray(override)) {
    for (const key in override) {
      base[key] = override[key];
    }
    return base;
  }
  return override === undefined ? base : override;
};

const ContextFormComponent = (
  {
    children,
    data,
    onChange,
    formID,
    nativeForm = true,
    /* native HTML form element props */
    action = "",
    autoComplete = "off",
    method = "",
    shouldHideAllToasts = false,
    confirmWithToastMessage = false,
  },
  ref
) => {
  const formContainerRef = useRef(null);
  // We need access to the node in the current component
  // as well as the parent component in some instances.
  // Setting the ref can either be with a useRef (which has a current)
  // or a function so here we are accounting for all cases.

  const setRef = (node) => {
    formContainerRef.current = node;
    if (typeof ref === "function") {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };
  // When the form is inside of a Dialog component we want to
  // only show API errors.
  const isInDialog = useIsInDialog(formContainerRef);
  const provider = (
    <FormReviewWarningsContextProvider
      ignoreWarnings={data?.ignore_warnings}
      setIgnoreWarnings={(warnings) => onChange({ ignore_warnings: warnings })}
      confirmWithToastMessage={confirmWithToastMessage}
    >
      <FormContextProvider
        data={data}
        onChange={onChange}
        formID={formID}
        shouldHideInlineToasts={isInDialog}
        shouldHideAllToasts={shouldHideAllToasts}
      >
        {children}
      </FormContextProvider>
    </FormReviewWarningsContextProvider>
  );
  return nativeForm ? (
    <form
      ref={setRef}
      action={action}
      autoComplete={autoComplete}
      method={method}
    >
      {provider}
    </form>
  ) : (
    <div ref={setRef}>{provider}</div>
  );
};

const ContextForm = forwardRef(ContextFormComponent);

ContextForm.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  onChange: PropTypes.func,
  nativeForm: PropTypes.bool,
  action: PropTypes.string,
  autoComplete: PropTypes.string,
  method: PropTypes.string,
  shouldHideAllToasts: PropTypes.bool,
};

ContextForm.Field = ContextFormField;
ContextForm.Action = Action;
ContextForm.ActionBar = ActionBar;

export default ContextForm;
