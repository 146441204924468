import OrganizationUser from "byzantine/src/OrganizationUser";
import Role, { MODEL_STR } from "byzantine/src/Role";
import { ContextForm, useFormData } from "cerulean";
import { formatNumber } from "@narmi/design_system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import utils from "../../utils";
import BaseBanner from "../BaseBanner";
import AccessLevelSection from "./AccessLevelSection";
import OrgUserActions from "./OrgUserActions";
import OrganizationAccessLevelExtraPermissions from "./OrganizationAccessLevelExtraPermissions";
import {
  bundleExtraPermissions,
  validateExtraPermissions,
} from "./OrganizationEditUser";
import UserInfoHeader from "./UserInfoHeader";
import UserInfoInputs from "./UserInfoInputs";

export default function OrganizationNewUser(props) {
  const dualApprovalRequired = JSON.parse(props.dualApprovalRequiredJson);
  const isUsingConsumerBillpay = !!JSON.parse(props.isUsingConsumerBillpayJson);
  const roles = JSON.parse(props.roles).map((role) => new Role(role));
  const [permissionsError, setPermissionsError] = useState(null);
  const defaultDailyACHLimit = props.features.limits?.ach_push?.[1]
    ? formatNumber(props.features.limits?.ach_push?.[1])
    : "";
  const defaultDailyWireLimit = props.features.limits?.wire?.[1]
    ? formatNumber(props.features.limits?.wire?.[1])
    : "";

  const initialData = {
    accounts: props.accounts,
    externalAccounts: props.externalAccounts,
    extraPermissions: {},
    ach_limits: defaultDailyACHLimit.toString(),
    wire_limits: defaultDailyWireLimit.toString(),
  };
  OrganizationAccessLevelExtraPermissions.affectedPermissions.forEach(
    (model) => {
      initialData.extraPermissions[model] = false;
    },
  );

  const { onChange, formData } = useFormData(initialData);

  const onSubmit = (callback) => {
    const error = validateExtraPermissions(formData);

    if (error) {
      setPermissionsError(error);
      callback();
      return;
    }
    formData.extraPermissions = bundleExtraPermissions(formData);
    let ach_limits = 0;
    let wire_limits = 0;
    if (formData.extraPermissions[MODEL_STR.ACH_TRANSFER]) {
      ach_limits = utils.parseValueAsFloat(formData.ach_limits);
    }
    if (formData.extraPermissions[MODEL_STR.WIRE_TRANSACTION]) {
      wire_limits = utils.parseValueAsFloat(formData.wire_limits);
    }
    const newUser = new OrganizationUser({
      user: {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone: formData.phone,
      },
      status: OrganizationUser.STATUS.PENDING,
      role: Role.generateFromAccessLevel(
        formData.accessLevel,
        formData.accounts,
        formData.externalAccounts,
        formData.extraPermissions,
        roles,
        { ach_limits, wire_limits },
      ),
      organization_uuid: props.orgId,
    });

    newUser
      .sendInvite()
      .then((response) => {
        let infoToast = "";
        if (
          response.organization_user?.status ===
            OrganizationUser.STATUS.PENDING &&
          response?.organization_user?.demo_invite_url
        ) {
          infoToast = `&info=Demo+only:+click+[here](${response?.organization_user?.demo_invite_url})+to+access+the+invite+link.`;
        }
        window.location = `/manage_users?success=Invite+sent.${infoToast}`;
      })
      .catch(callback);
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <BaseBanner
        bannerName="Add a new user"
        goBack={() => window.location.assign("/manage_users")}
      />
      <ContextForm data={formData} onChange={onChange}>
        <div className="section-box">
          <UserInfoHeader />
          <UserInfoInputs />
        </div>
        <AccessLevelSection
          formData={formData}
          features={props.features}
          roles={roles}
          organizationUsers={props.organizationUsers}
          errorMessage={permissionsError}
          onChange={onChange}
          dualApprovalRequired={dualApprovalRequired}
          isUsingConsumerBillpay={isUsingConsumerBillpay}
        />
        <OrgUserActions onSubmit={onSubmit} dangerouslyDisableShowLoading />
      </ContextForm>
    </div>
  );
}

OrganizationNewUser.propTypes = {
  accounts: PropTypes.array,
  externalAccounts: PropTypes.array,
  orgId: PropTypes.string.isRequired,
  roles: PropTypes.string.isRequired,
  features: PropTypes.object,
  organizationUsers: PropTypes.array.isRequired,
  dualApprovalRequiredJson: PropTypes.string,
  isUsingConsumerBillpayJson: PropTypes.string,
};
OrganizationNewUser.defaultProps = {
  accounts: [],
  externalAccounts: [],
};
