import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@narmi/design_system";
import { useNotificationContext } from "../NotificationContext";

/* TO BE DELETED WHEN NDS'S ERROR COMPONENT IS EXPOSED */
const Error = ({ error = "" }) => {
  if (!error) return null;
  return (
    <div className="nds-input-error">
      <div className="fontSize--s margin--right--xxs narmi-icon-x-circle" />
      {error}
    </div>
  );
};

const UploadStatusIcon = ({ isUploading }) => (
  <div className="status-icon-circle-container">
    <div className="narmi-icon-solid-circle" />
    <div className="status-icon-wrapper">
      <div
        className={isUploading ? "narmi-icon-refresh-cw" : "narmi-icon-check"}
      />
    </div>
  </div>
);
UploadStatusIcon.propTypes = {
  isUploading: PropTypes.bool,
};

const FileUploader = ({
  sendRequest,
  onChange,
  value,
  error,
  fileKey,
  expand = false,
  messages = {
    errorNoFile: "No file detected. Please try again",
    errorMultipleUnsupported: "Multiple file uploads unsupported. Please try again",
    errorUnsupportedType: "Unfortunately, we don't support this file type. Please upload a supported file type such as .jpg, .pdf, .csv, .ppt, .doc, etc.",
    errorFailed: "Upload failed. Please try again",
    label: "Drag and drop here or click to upload a file",
  },
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  /* when uploadedFileName doesn't have a value, the file-uploader view is displayed.
     otherwise, the uploaded-file view is displayed. */
  const [uploadedFileName, setUploadedFileName] = useState(value || null);
  const [isUploading, setIsUploading] = useState(false);
  const nativeInputRef = useRef(null);

  const { sendNotification } = useNotificationContext();

  const validateFiles = (files) => {
    if (!files) {
      sendNotification({
        type: "negative",
        text: messages.errorNoFile,
      });
      return false;
    }
    if (files.length > 1) {
      sendNotification({
        type: "negative",
        text: messages.errorMultipleUnsupported,
      });
      return false;
    }
    return true;
  };

  const onUpload = async (file) => {
    setUploadedFileName(file.name);
    setIsUploading(true);
    try {
      /* do the api call */
      await sendRequest(file);
      onChange(file.name);
    } catch (error) {
      const error_string = "Inbound file is not allowed!";
      if (error.toLowerCase().includes(error_string.toLowerCase())) {
        sendNotification({
          type: "negative",
          text: messages.errorUnsupportedType,
        });
      } else {
        sendNotification({
          type: "negative",
          text: messages.errorFailed,
        });
      }
      setUploadedFileName("");
    } finally {
      setIsUploading(false);
    }
  };

  // handle drag events
  const handleDragEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (["dragenter", "dragover"].includes(e.type)) {
      setIsDragActive(true);
    } else if (e.type === "dragleave") {
      setIsDragActive(false);
    }
  };

  // triggers when file is dragged and dropped
  const handleDropUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    if (validateFiles(e.dataTransfer.files)) {
      onUpload(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleClickUpload = (e) => {
    e.preventDefault();

    if (validateFiles(e.target.files)) {
      onUpload(e.target.files[0]);
    }
  };

  const softRemoveUploadedFile = () => {
    setUploadedFileName(null);
    onChange(null);
  };

  // triggers the native input when the 'click to upload file' button is clicked
  const triggerNativeInput = () => {
    nativeInputRef.current.click();
  };

  /* the file-uploader view */
  const noUploadedFileState = (
    <>
      <input
        ref={nativeInputRef}
        type="file"
        style={{display: "none"}}
        id={`native-input-file-${fileKey}`}
        onChange={handleClickUpload}
      />
      <label
        id="file-uploader-label"
        htmlFor={`native-input-file-${fileKey}`}
        className={isDragActive ? "drag-active" : ""}
      >
        <div
          className="narmi-icon-upload"
          style={{ paddingRight: "var(--space-s)" }}
        />
        <div>
          <button
            type="button"
            className="upload-button"
            onClick={triggerNativeInput}
            style={{ color: "RGB(var(--nds-black))" }}
          >
            {messages.label}
          </button>
        </div>
      </label>
    </>
  );

  /* the "uploaded-file" view*/
  const hasUploadedFileState = (
    // can replace the uploaded file by dragging a new file into the box
    <div id="uploaded-file-box" className={isDragActive ? "drag-active" : ""}>
      <UploadStatusIcon isUploading={isUploading} />
      <div className="uploaded-file-name">{uploadedFileName}</div>
      <div className="x-button">
        <Button
          kind="plain"
          type="button"
          startIcon="x"
          onClick={softRemoveUploadedFile}
        />
      </div>
    </div>
  );

  return (
    <>
      <div
        id="file-uploader-wrapper"
        style={{ maxWidth: expand ? "100%" : null }}
        onDragEnter={handleDragEvent}
      >
        {uploadedFileName ? hasUploadedFileState : noUploadedFileState}
        {isDragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDragEvent}
            onDragLeave={handleDragEvent}
            onDragOver={handleDragEvent}
            onDrop={handleDropUpload}
          />
        )}
      </div>
      <Error error={error} />
    </>
  );
};
FileUploader.propTypes = {
  sendRequest: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.string,
  fileKey: PropTypes.string,
  messages: PropTypes.object,
};

export default FileUploader;
