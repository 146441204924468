// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardManagerDrawerContainer--IKyEj{width:335px;margin:0 auto}.cardImageContainer--IgGcd{margin-bottom:28px}.bannerInfo--P5kg8{margin-bottom:var(--space-l)}.bannerInfo--P5kg8 div.nds-alert{padding:var(--space-s) !important;font-size:var(--font-size-s)}@media only screen and (min-width: 470px){.cardManagerDrawerContainer--IKyEj{width:390px}}", "",{"version":3,"sources":["webpack://./components/settings/cards/CardManagerDrawer/CardManagerDrawer.module.scss"],"names":[],"mappings":"AAEA,mCACE,WAAA,CACA,aAAA,CAGF,2BACE,kBAAA,CAGF,mBACE,4BAAA,CAEA,iCACE,iCAAA,CAGA,4BAAA,CAIJ,0CAIE,mCACE,WAAA,CAAA","sourcesContent":["@import \"../../../../../scss/mixins\";\n\n.cardManagerDrawerContainer {\n  width: 335px;\n  margin: 0 auto;\n}\n\n.cardImageContainer {\n  margin-bottom: 28px;\n}\n\n.bannerInfo {\n  margin-bottom: var(--space-l);\n\n  div:global(.nds-alert) {\n    padding: var(\n      --space-s\n    ) !important; // padding-all on the Alert is aggressive and uses !important\n    font-size: var(--font-size-s);\n  }\n}\n\n@media only screen and (min-width: 470px) {\n  // there isn't current a breakpoint variable for this size, so we're just\n  // defining it specifically as 470px\n  // at this width, everything becomes wider\n  .cardManagerDrawerContainer {\n    width: 390px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardManagerDrawerContainer": "cardManagerDrawerContainer--IKyEj",
	"cardImageContainer": "cardImageContainer--IgGcd",
	"bannerInfo": "bannerInfo--P5kg8"
};
export default ___CSS_LOADER_EXPORT___;
