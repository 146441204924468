import React, { useState } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { formatNumber, Tag } from "@narmi/design_system";
import Filters from "byzantine/src/filters";
import Transaction, { isABalance } from "byzantine/src/Transaction";
import NewThreadDialog from "./NewThreadDialog";
import TransactionDetailsDialog from "./TransactionDetailsDialog";

const TransactionRow = ({
  isSourceMultipleAccounts,
  transaction,
  replyTime,
}) => {
  const {
    account,
    creation_date,
    description,
    amount,
    resulting_balance,
    posted_date,
  } = transaction;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isReportIssueDialogOpen, setIsReportIssueDialogOpen] = useState(false);

  // need to tack on a `+` sign if the amount is positive since NDS.formatNumber does not do that for us
  const displayedAmount = `${amount > 0 ? `+` : ``}${formatNumber(
    amount / 100,
    "currency"
  )}`;
  // display either resulting balance or estimated resulting balance for pending if we can
  const displayedResultingBalance = isABalance(resulting_balance)
    ? formatNumber(resulting_balance / 100)
    : null;

  const transactionTitle = (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flexShrink: "1",
          alignSelf: "center",
          overflowWrap: "anywhere",
        }}
      >
        {description}
      </div>
      {!posted_date && (
        <div className="padding--left--xs">
          <Tag label="Pending" kind="outline" />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            setIsDialogOpen(true);
          }
        }}
        onClick={() => setIsDialogOpen(true)}
        className={"row-wrapper transaction-row clickable"}
      >
        <div className="row-items-column reverse">
          <div className="row-item">
            <div data-testid="transaction-title">{transactionTitle}</div>
            <div className="font-size-s-medium-grey">
              {Filters.longMonthDayYear(posted_date || creation_date)}
            </div>
          </div>
          {isSourceMultipleAccounts && (
            <div className="row-item account-name">
              {account?.nickname || account?.name}
            </div>
          )}
        </div>
        <div className="row-items-column">
          <div className="row-item align-right number">{displayedAmount}</div>
          {!isSourceMultipleAccounts && (
            <div className="row-item align-right number responsive-text-styling">
              {displayedResultingBalance}
            </div>
          )}
        </div>
      </div>
      <TransactionDetailsDialog
        transaction={transaction}
        transactionTitle={transactionTitle}
        displayedAmount={displayedAmount}
        displayedResultingBalance={displayedResultingBalance}
        isSourceMultipleAccounts={isSourceMultipleAccounts}
        isDialogOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
        ReportIssueCallback={() => {
          setIsReportIssueDialogOpen(true);
        }}
      />
      <NewThreadDialog
        accountNumber={transaction.account.number}
        transactionId={transaction.id}
        isDialogOpen={isReportIssueDialogOpen}
        closeDialog={() => setIsReportIssueDialogOpen(false)}
        replyTime={replyTime}
      />
    </>
  );
};
TransactionRow.propTypes = {
  isSourceMultipleAccounts: PropTypes.bool.isRequired,
  transaction: PropTypes.instanceOf(Transaction).isRequired,
  replyTime: PropTypes.string,
};

const TransactionTable = ({
  isSourceMultipleAccounts,
  transactions,
  replyTime,
}) => {
  /*
    table content slightly differs depending on whether transactions from 1 or 1+ accounts are displayed
    when `isSourceMultipleAccounts` is true, the headers are: DESCRIPTION | **ACCOUNT** | AMOUNT
    when `isSourceMultipleAccounts` is false, the headers are: DESCRIPTION | AMOUNT | **BALANCE**
   */
  let mutatedTransactions = transactions.slice();
  if (!isSourceMultipleAccounts) {
    // calculate estimated remaining balance when we don't know it,
    // only possible when we have transaction list for single account
    mutatedTransactions =
      Transaction.calculatePendingBalances(mutatedTransactions);
  }
  return (
    <div
      className={cc([
        "card-table transactionTable no-table-top-border",
        { sparse: isSourceMultipleAccounts },
      ])}
    >
      <div className="row-wrapper">
        <div className="row-item-header">DESCRIPTION</div>
        {isSourceMultipleAccounts && (
          <div className="row-item-header">ACCOUNT</div>
        )}
        <div className="row-item-header align-right">AMOUNT</div>
        {!isSourceMultipleAccounts && (
          <div className="row-item-header align-right">BALANCE</div>
        )}
      </div>
      {mutatedTransactions.map((t) => (
        <TransactionRow
          key={t.id}
          isSourceMultipleAccounts={isSourceMultipleAccounts}
          transaction={t}
          replyTime={replyTime}
        />
      ))}
    </div>
  );
};
TransactionTable.propTypes = {
  isSourceMultipleAccounts: PropTypes.bool.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction)).isRequired,
  replyTime: PropTypes.string,
};

export const EmptyTransactionState = ({ isSourceMultipleAccounts }) => (
  /* for the "wow such empty" state when there are no transactions */
  <div className="padding--y--l">
    <div className="fontWeight--bold padding--top--xs">
      You have no transactions
    </div>
    <div className="padding--top--xxs">
      Once you have {isSourceMultipleAccounts && "recent "}transaction activity,
      it&apos;ll show up here
    </div>
  </div>
);
EmptyTransactionState.propTypes = {
  isSourceMultipleAccounts: PropTypes.bool.isRequired,
};

export default TransactionTable;
