var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DateTime } from "luxon";
import User from "./User";
import Filters from "./filters";
import Balances from "./Balances";
import ApiHttp from "./ApiHttp";
import utils from "./utils";
import { ACCOUNT_PRODUCT_GROUP } from "./dbbl/businessLogic/entities/helpers/accounts.helpers";
import { formatNumber } from "./dbbl/businessLogic/entities/utils/currency";
export var AccountType = {
    Deposit: "deposit",
    Credit: "credit",
    Debit: "debit",
};
export var ProductType = {
    Savings: "savings",
    Checking: "checking",
    CertificateOfDeposit: "certificate_of_deposit",
    HSA: "hsa",
    IRA: "ira",
    IRACertificateOfDeposit: "ira_certificate_of_deposit",
    MoneyMarket: "money_market",
    LineOfCredit: "line_of_credit",
    CreditCard: "credit_card",
    Loan: "loan",
    Mortgage: "mortgage",
    Lease: "lease",
    Unknown: "unknown",
};
var defaultFormatters = {
    currency: Filters.currency,
    percentage: Filters.percent,
    date: Filters.americanDate,
};
function isNotEmpty(data) {
    return !!data && Object.keys(data).length > 0;
}
var Account = /** @class */ (function () {
    function Account(props) {
        Object.assign(this, props);
        // External accounts shouldn't ever be favorited,
        // for now just hardcode this until we feel right to do a data migration
        this.favorited = props.source === "institution" ? !!props.favorited : false;
        this.hidden = !!props.hidden;
        this.users = props.users || [];
        this.features = props.features || [];
        this.loan_details = __assign({}, (props.loan_details || {}));
        this.metadata = __assign({}, (props.metadata || {}));
        this.balances = props.balances || new Balances({});
    }
    Account.prototype.getProductGroup = function () {
        // Get the product group type, ignoring the "Favorites" special case
        var wasFavorited = this.favorited;
        this.favorited = false;
        var type = this.getGroupName();
        this.favorited = wasFavorited;
        return type;
    };
    Account.prototype.getBalanceHeadline = function () {
        var group = this.getProductGroup();
        var isCurrentBalanceHeadline = this.account_type === "deposit" ||
            ["credit_card", "mortgage"].includes(this.product_type || "");
        switch (group) {
            case ACCOUNT_PRODUCT_GROUP.CHECKING:
            case ACCOUNT_PRODUCT_GROUP.SAVINGS:
                return [
                    {
                        type: "available-balance",
                        title: "Available balance",
                        value: this.balances.available,
                    },
                ];
            default:
                if (isCurrentBalanceHeadline) {
                    return [
                        {
                            type: "current-balance",
                            title: "Current balance",
                            value: this.balances.ledger,
                        },
                    ];
                }
                else {
                    return [
                        {
                            type: "default",
                            title: "Balance",
                            value: this.balances.ledger,
                        },
                    ];
                }
        }
    };
    Account.prototype.getPaymentHeadlines = function () {
        var _a, _b;
        if (["", null, undefined].includes((_a = this.loan_details) === null || _a === void 0 ? void 0 : _a.minimum_payment))
            return [];
        var formattedDate = "";
        var paymentDetails = [
            this.product_type === "credit_card" ? "Minimum payment" : "Next payment",
        ];
        var type = this.product_type === "credit_card" ? "minimum-payment" : "next-payment";
        // display when the next payment is due if it exists
        if ((_b = this.loan_details) === null || _b === void 0 ? void 0 : _b.next_payment_at) {
            formattedDate = Filters.americanDate(this.loan_details.next_payment_at);
            var paymentDueDate = "(due ".concat(formattedDate, ")");
            paymentDetails.push(paymentDueDate);
        }
        return [
            {
                type: type,
                nextPaymentDate: formattedDate,
                title: paymentDetails.join(" "),
                value: formatNumber(this.loan_details.minimum_payment),
            },
        ];
    };
    Account.prototype.getMiscHeadlines = function () {
        var _a, _b;
        var miscHeadlines = [];
        // display maturity date if it exists
        if ((_b = (_a = this.metadata) === null || _a === void 0 ? void 0 : _a.maturity_date) === null || _b === void 0 ? void 0 : _b.value) {
            miscHeadlines.push({
                type: "maturity-date",
                title: "Maturity date",
                value: this.formattedMetadata().maturity_date.value,
            });
        }
        return miscHeadlines;
    };
    Account.prototype.displayHeadlineData = function () {
        var contents = [];
        contents.push.apply(contents, __spreadArray([], __read(this.getBalanceHeadline()), false));
        contents.push.apply(contents, __spreadArray([], __read(this.getPaymentHeadlines()), false));
        contents.push.apply(contents, __spreadArray([], __read(this.getMiscHeadlines()), false));
        return contents;
    };
    // Headline stats are split into helper functions based on headline type.
    // These functions also ship with a specific type property used for headline translation
    Account.prototype.getHeadlineStats = function () {
        var contents = [];
        contents.push.apply(contents, __spreadArray([], __read(this.getBalanceHeadline()), false));
        contents.push.apply(contents, __spreadArray([], __read(this.getPaymentHeadlines()), false));
        contents.push.apply(contents, __spreadArray([], __read(this.getMiscHeadlines()), false));
        return contents.map(function (_a) {
            var type = _a.type, restContents = __rest(_a, ["type"]);
            return restContents;
        });
    };
    Account.prototype.getMaskedNumber = function () {
        var _a;
        // First try to split from (e.g. 122=X) into a number (e.g. 123) if needed
        var accountAndShareId = ((_a = this.number) === null || _a === void 0 ? void 0 : _a.split("=")) || [""];
        var accountNumber = accountAndShareId[0];
        if (accountNumber.length <= 4)
            return this.number || "";
        // show suffix if it has one, else last four of number
        return accountAndShareId.length > 1
            ? "".concat(accountNumber.substring(accountNumber.length - 2), "=").concat(accountAndShareId[1])
            : accountNumber.substring(accountNumber.length - 4);
    };
    Account.prototype.getName = function () {
        return this.nickname || this.name || "";
    };
    Account.prototype.getNameWithoutMaskedAccountNumber = function () {
        var _a;
        // (\*{2,}[0-9]{2,})?  Two or more stars and two or more numbers is present or not present.
        // ([-=])?             Match one or none for dash or equals.
        // *{2,}               Two or more stars.
        // [0-9]{2,}$          Two or more numbers at the end of the string.
        var maskedAccountNumberRegex = /(\*{2,}[0-9]{2,})?([-=])?\*{2,}[0-9]{2,}$/;
        return ((_a = this.name) === null || _a === void 0 ? void 0 : _a.replace(maskedAccountNumberRegex, "").trim()) || "";
    };
    Account.prototype.getShortDescription = function (includeSpaces) {
        if (includeSpaces === void 0) { includeSpaces = true; }
        var nicknameOrName = this.getName();
        // External account names are serialized to include the number, so don't add the number to the customer facing name
        if (this.isExternal()) {
            return nicknameOrName;
        }
        var separator = includeSpaces ? " - " : "-";
        return "".concat(nicknameOrName).concat(separator).concat(this.getMaskedNumber());
    };
    Account.prototype.getDescription = function (isDestination) {
        var _a, _b;
        if (isDestination === void 0) { isDestination = false; }
        var shortDescription = this.getShortDescription();
        // use available balance unless it is a credit account used as a destination
        var relevantBalance = isDestination && this.isCredit()
            ? (_a = this.balances) === null || _a === void 0 ? void 0 : _a.ledger
            : (_b = this.balances) === null || _b === void 0 ? void 0 : _b.available;
        if (!relevantBalance) {
            return shortDescription;
        }
        return "".concat(shortDescription, " (").concat(relevantBalance, ")");
    };
    Account.prototype.getGroupName = function () {
        if (this.favorited)
            return "Favorites";
        var productType = this.isExternal()
            ? "external_account"
            : this.product_type;
        return Object.entries(Account.PRODUCT_GROUPS).reduce(function (prevGroupName, current) {
            var _a = __read(current, 2), groupName = _a[0], productTypes = _a[1];
            if (productTypes.includes(String(productType).toLowerCase().replace(/ /g, "_")))
                return groupName;
            return prevGroupName;
        }, "Others");
    };
    Account.prototype.isInternal = function () {
        return this.source === "institution";
    };
    Account.prototype.isExternal = function () {
        return !this.isInternal();
    };
    Account.prototype.isCredit = function () {
        return this.account_type === "credit";
    };
    Account.prototype.isDeposit = function () {
        return !this.isCredit();
    };
    Account.prototype.isCD = function () {
        return Account.PRODUCT_GROUPS.CDs.includes(this.product_type || "");
    };
    Account.prototype.isChecking = function () {
        return Account.PRODUCT_GROUPS.Checking.includes(this.product_type || "");
    };
    Account.prototype.isInternalLoan = function () {
        return (this.isInternal() &&
            Account.PRODUCT_GROUPS.Loans.includes(this.product_type || ""));
    };
    Account.prototype.isValidTransferSource = function () {
        return (this.features.indexOf("transfer_source") > -1 &&
            !this.features.includes("zero_balance"));
    };
    Account.prototype.isValidAchTransferSource = function () {
        // balance check was performed before granting this feature to the account
        return this.features.includes("ach_source");
    };
    Account.prototype.isValidTransferDestinationForSource = function (sourceAccount, achAllowsPush, supportsInternalTransfers) {
        if (supportsInternalTransfers === void 0) { supportsInternalTransfers = true; }
        if (!sourceAccount) {
            return (this.isValidTransferDestination() ||
                (achAllowsPush && this.isValidAchTransferDestination()));
        }
        if (sourceAccount.id === this.id)
            return false;
        if (sourceAccount.isExternal()) {
            // Assume here that ACH pull must be allowed for external source to have been chosen
            return this.isInternal() && this.isValidAchTransferDestination();
        }
        // at this point, we know that the source account is internal
        // destination cannot be internal if internal transfers are not supported
        if (!supportsInternalTransfers && this.isInternal())
            return false;
        // if the source account does not support ACHs, destination cannot be external
        if (!sourceAccount.isValidAchTransferSource() && this.isExternal())
            return false;
        return this.isValidTransferDestination();
    };
    Account.prototype.isValidTransferSourceForDestination = function (destinationAccount, supportsInternalTransfers) {
        if (supportsInternalTransfers === void 0) { supportsInternalTransfers = true; }
        if (!destinationAccount) {
            return this.isValidTransferSource();
        }
        if (destinationAccount.id === this.id)
            return false;
        if (destinationAccount.isExternal()) {
            // Assume here that ACH pull must be allowed for external destination to have been chosen
            return this.isInternal() && this.isValidAchTransferSource();
        }
        // at this point, we know that the destination account is internal
        // source cannot be internal if internal transfers are not supported
        if (!supportsInternalTransfers && this.isInternal())
            return false;
        // if the destination account does not support ACHs, source cannot be external
        if (!destinationAccount.isValidAchTransferDestination() &&
            this.isExternal())
            return false;
        return this.isValidTransferSource();
    };
    Account.prototype.isValidTransferDestination = function () {
        return this.features.indexOf("transfer_destination") > -1;
    };
    Account.prototype.isValidAchTransferDestination = function () {
        return this.features.indexOf("ach_destination") > -1;
    };
    Account.prototype.isValidInternalOrExternalTransferSource = function (achAllowsPull) {
        return this.isValidTransferSource() && (achAllowsPull || this.isInternal());
    };
    Account.prototype.isValidInternalOrExternalTransferDestination = function (achAllowsPush) {
        return (this.isValidTransferDestination() && (achAllowsPush || this.isInternal()));
    };
    Account.prototype.isValidBillpaySource = function () {
        return this.isInternal() && this.isChecking();
    };
    Account.prototype.isPayableByCard = function () {
        return (this.isInternal() &&
            ["mortgage", "lease", "loan"].includes(this.product_type || ""));
    };
    Account.prototype.isAmortizedLoan = function () {
        var _a, _b;
        return ((_b = (_a = this === null || this === void 0 ? void 0 : this.metadata) === null || _a === void 0 ? void 0 : _a.loan_amortized) === null || _b === void 0 ? void 0 : _b.value) === "yes";
    };
    Account.prototype.availableBalanceAsFloat = function () {
        return this.balances.available
            ? parseFloat(this.balances.available.replace(/[^\d.-]/g, ""))
            : 0;
    };
    Account.prototype.transferableBalanceAsFloat = function () {
        var _a;
        if ((_a = this.metadata.transferable_balance) === null || _a === void 0 ? void 0 : _a.value) {
            return parseFloat(this.metadata.transferable_balance.value);
        }
        return this.availableBalanceAsFloat();
    };
    Account.prototype.ledgerBalanceAsFloat = function () {
        return this.balances.ledger
            ? parseFloat(this.balances.ledger.replace(/[^\d.-]/g, ""))
            : 0;
    };
    Account.prototype.primaryBalanceAsFloat = function () {
        return this.balances.primary
            ? parseFloat(this.balances.primary.replace(/[^\d.-]/g, ""))
            : 0;
    };
    Account.prototype.formattedMetadata = function (externalFormatters) {
        // remap names in case we don't name things consistently
        var _a, _b;
        if (externalFormatters === void 0) { externalFormatters = defaultFormatters; }
        var noop = function (x) { return x; };
        var formatters = function (format) {
            return externalFormatters[format] || defaultFormatters[format] || noop;
        };
        if (this.metadata.previous_payment_at) {
            this.metadata.previous_payment_date = this.metadata.previous_payment_at;
            delete this.metadata.previous_payment_at;
        }
        if (this.metadata.regular_loan_payment) {
            this.metadata.regular_payment = this.metadata.regular_loan_payment;
            delete this.metadata.regular_loan_payment;
        }
        if (this.metadata.loan_amortized && this.metadata.loan_amortized.public) {
            this.metadata.loan_amortized.public = false;
        }
        var formattedMetadata = Object.entries(this.metadata)
            .filter(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], values = _b[1];
            return values && values.value && values.public;
        }) // eslint-disable-line no-unused-vars
            .reduce(function (previous, _a) {
            var _b = __read(_a, 2), key = _b[0], _c = _b[1], format = _c.format, value = _c.value;
            try {
                previous[key] = {
                    label: Filters.humanize(Filters.titlecase(key)),
                    value: formatters(format)(format === "date"
                        ? DateTime.fromISO(value).toJSDate()
                        : value),
                };
            }
            catch (_d) {
                /* if formatting errors out, omit from returned formatted metadata */
            }
            return previous;
        }, {});
        // include account details not contained within the metadata property
        var otherDetails = {
            accountNumber: { label: "Account number", value: this === null || this === void 0 ? void 0 : this.number },
            routingNumber: { label: "Routing number", value: this === null || this === void 0 ? void 0 : this.routing },
            checkMicr: { label: "Check MICR", value: this === null || this === void 0 ? void 0 : this.check_micr },
            availableCredit: {
                label: "Available credit",
                value: this === null || this === void 0 ? void 0 : this.balances.available,
            },
            minimumPayment: {
                label: "Minimum payment",
                value: ((_a = this === null || this === void 0 ? void 0 : this.loan_details) === null || _a === void 0 ? void 0 : _a.minimum_payment)
                    ? formatters("currency")(this.loan_details.minimum_payment)
                    : null,
            },
            apr: {
                label: "APR",
                value: ((_b = this === null || this === void 0 ? void 0 : this.loan_details) === null || _b === void 0 ? void 0 : _b.interest_rate)
                    ? formatters("percentage")(this.loan_details.interest_rate)
                    : null,
            },
        };
        return __assign(__assign({}, formattedMetadata), otherDetails);
    };
    Account.prototype.accountDetails = function (externalFormatters) {
        if (externalFormatters === void 0) { externalFormatters = defaultFormatters; }
        var _a = this.formattedMetadata(externalFormatters), payoff_amount = _a.payoff_amount, accountNumber = _a.accountNumber, routingNumber = _a.routingNumber, checkMicr = _a.checkMicr, availableCredit = _a.availableCredit, maturity_date = _a.maturity_date, interest_rate = _a.interest_rate, dividend_rate = _a.dividend_rate, minimumPayment = _a.minimumPayment, past_due = _a.past_due, regular_payment = _a.regular_payment, previous_payment_date = _a.previous_payment_date, apr = _a.apr, otherMetadata = __rest(_a, ["payoff_amount", "accountNumber", "routingNumber", "checkMicr", "availableCredit", "maturity_date", "interest_rate", "dividend_rate", "minimumPayment", "past_due", "regular_payment", "previous_payment_date", "apr"]);
        var showAvailableCredit = ["credit_card", "line_of_credit"].includes(this.product_type || "");
        var defaultDetails = [
            payoff_amount,
            accountNumber,
            routingNumber,
            checkMicr,
            showAvailableCredit ? availableCredit : null,
            maturity_date,
            interest_rate || dividend_rate,
            minimumPayment,
            past_due,
            regular_payment,
            previous_payment_date,
        ].filter(function (detail) { return (detail === null || detail === void 0 ? void 0 : detail.label) && (detail === null || detail === void 0 ? void 0 : detail.value); });
        var expandedDetails = __spreadArray([apr], __read(Object.values(otherMetadata)), false).filter(function (detail) { return (detail === null || detail === void 0 ? void 0 : detail.label) && (detail === null || detail === void 0 ? void 0 : detail.value); });
        return { defaultDetails: defaultDetails, expandedDetails: expandedDetails };
    };
    Account.prototype.getPreviousPaymentDateValue = function () {
        var _a, _b, _c, _d;
        /* `previous_payment_at` gets mutated into `previous_payment_date` so we should check both */
        return (((_b = (_a = this.metadata) === null || _a === void 0 ? void 0 : _a.previous_payment_date) === null || _b === void 0 ? void 0 : _b.value) ||
            ((_d = (_c = this.metadata) === null || _c === void 0 ? void 0 : _c.previous_payment_at) === null || _d === void 0 ? void 0 : _d.value));
    };
    Account.prototype.canCalculateLoanPayoff = function (userFeatures) {
        return (this.product_type === "loan" &&
            !userFeatures.hide_loan_calculator &&
            Boolean(this.metadata &&
                this.metadata.interest_type &&
                this.metadata.interest_type.value === "365" &&
                this.metadata.interest_days &&
                this.metadata.interest_days.value &&
                this.getPreviousPaymentDateValue()));
    };
    Account.prototype.calculatePayoffInfo = function (date) {
        var from = DateTime.fromJSDate(new Date(this.getPreviousPaymentDateValue()));
        var to = DateTime.fromJSDate(new Date(date));
        var days = to.diff(from, "days").as("days");
        var interestDays = Number(this.metadata.interest_days.value);
        var dayRate = this.loan_details.interest_rate / interestDays;
        var principal = utils.parseValueAsFloat(this.balances.ledger || 0) * 100;
        var compoundedBalance = principal;
        for (var i = 0; i < days; i += 1) {
            compoundedBalance = compoundedBalance * dayRate + compoundedBalance;
        }
        return {
            total: compoundedBalance,
            interest: compoundedBalance - principal,
            principal: principal,
            payoffDate: date,
        };
    };
    Account.prototype.stopCheckPayment = function (payload) {
        if ((payload === null || payload === void 0 ? void 0 : payload.amount) && typeof payload.amount === "string") {
            payload.amount = utils.dollarsToPennies(payload.amount.replace(/[^\d.]/g, ""));
        }
        var url = "accounts/".concat(this.id, "/stops");
        return ApiHttp.fetch(url, { method: "POST" }, payload);
    };
    Account.prototype.withdrawCashiersCheck = function (payload) {
        payload.amount = utils.dollarsToPennies(payload.amount.replace(/[^\d.]/g, ""));
        var url = "accounts/".concat(this.id, "/withdrawals");
        return ApiHttp.fetch(url, { method: "POST" }, payload);
    };
    Account.prototype.toggleFavorited = function () {
        return ApiHttp.fetch("accounts/".concat(this.id), { method: "PUT" }, { favorited: !this.favorited });
    };
    Account.prototype.toggleHidden = function () {
        return ApiHttp.fetch("accounts/".concat(this.id), { method: "PUT" }, { hidden: !this.hidden });
    };
    /**
     * Notably, if the `accounts` param contains both deposit and credit accounts,
     * this will return the sum of the primary balance of deposit accounts ONLY.
     * This is because favorited account collections can include both types of accounts,
     * and mixing credit and deposit values is confusing.
     *
     * @param {Array} accounts - Account objects to sum the balance of
     * @returns {Number} the summed balance of all accounts.
     */
    Account.sumBalancesForAccounts = function (accounts) {
        if (!accounts.every(function (a) { return a.isInternal(); }))
            throw Error("must be internal accounts");
        var allCredit = accounts.every(function (a) { return a.isCredit(); });
        var subset = !allCredit
            ? accounts.filter(function (a) { return a.isDeposit(); })
            : accounts;
        return subset.reduce(function (pv, cv) { return pv + cv.primaryBalanceAsFloat(); }, 0);
    };
    Account.getAccountFromId = function (accountId, accounts) {
        if (!accounts || accounts.length < 1) {
            return null;
        }
        return accounts.find(function (account) { return account.id === accountId; });
    };
    Account.getTransactionType = function (to_account_id, from_account_id, accounts) {
        var toAccount = accounts.find(function (a) { return a.id === to_account_id; });
        var fromAccount = accounts.find(function (a) { return a.id === from_account_id; });
        var transactionType = (toAccount === null || toAccount === void 0 ? void 0 : toAccount.account_type) === "credit" ||
            (fromAccount === null || fromAccount === void 0 ? void 0 : fromAccount.product_type) === "certificate_of_deposit"
            ? "Payment"
            : "Transfer";
        return transactionType;
    };
    Account.addExternalAccount = function (payload) {
        return ApiHttp.fetch("accounts", { method: "POST" }, __assign(__assign({}, payload), { agreement: true }));
    };
    Account.prototype.updateBalances = function (balances) {
        if (balances.account_id && balances.account_id !== this.id) {
            return;
        }
        else if (balances instanceof Balances) {
            this.balances = balances;
        }
        else {
            this.balances = Balances.deserialize(__assign(__assign({}, balances), { account_id: this.id }));
        }
    };
    /**
     * Factory method returning a new instance of Account from
     * an indigo.serialized Account
     * */
    Account.deserialize = function (payload) {
        var _a, _b;
        var balances = payload.balances, loan_details = payload.loan_details, rest = __rest(payload, ["balances", "loan_details"]);
        var loanDetails = isNotEmpty(loan_details)
            ? __assign(__assign({}, loan_details), { minimum_payment: Filters.penniesToDollars(loan_details.minimum_payment).toString() })
            : {};
        var account = new Account(__assign(__assign({}, rest), { loan_details: loanDetails, account_type: payload.type, product_type: (_a = payload.product) === null || _a === void 0 ? void 0 : _a.type, 
            // WARNING: AccountUsers only contain the user UUID and nothing else
            users: (_b = payload.users) === null || _b === void 0 ? void 0 : _b.map(function (payloadUser) { return new User(payloadUser); }) }));
        if (isNotEmpty(balances)) {
            account.updateBalances(balances);
        }
        return account;
    };
    Account.prototype.serialize = function () {
        return {
            id: this.id,
            name: this.nickname,
            favorited: this.favorited,
        };
    };
    // taken from integrations.base Account types, also includes the EXTERNAL_ACCOUNTS group
    Account.PRODUCT_GROUPS = {
        Favorites: ["favorites"],
        Checking: ["checking"],
        Savings: ["savings", "hsa", "ira", "money_market"],
        "Credit cards": ["credit_card"],
        Loans: ["line_of_credit", "mortgage", "lease", "loan"],
        CDs: ["certificate_of_deposit", "ira_certificate_of_deposit"],
        Others: ["unknown"],
        "External accounts": ["external_account"],
    };
    return Account;
}());
export default Account;
