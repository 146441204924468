var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useCallback, useEffect, } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchIsSudo } from "./utils";
export var SudoRoutes;
(function (SudoRoutes) {
    SudoRoutes["RequestOTP"] = "/sudo/request-otp";
    SudoRoutes["SubmitOTP"] = "/sudo/submit-otp";
})(SudoRoutes || (SudoRoutes = {}));
var defaultSudoContext = {
    originalRoute: null,
    destinationRoute: null,
    formData: null,
    setFormData: function () { },
    onFormDataChange: function () { },
    establishSudo: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    onEstablishedSudo: function () { return Promise.resolve(); },
    cancelSudo: function () { },
    useSetInitialSteps: function () { },
    totalSteps: null,
};
export var NUMBER_OF_SUDO_STEPS = 1;
var SudoContext = createContext(defaultSudoContext);
export var useSudoContext = function () { return useContext(SudoContext); };
export var SudoProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(null), 2), onEstablishedSudo = _b[0], setOnEstablishedSudo = _b[1];
    var _c = __read(useState(defaultSudoContext.originalRoute), 2), originalRoute = _c[0], setOriginalRoute = _c[1];
    var _d = __read(useState(defaultSudoContext.destinationRoute), 2), destinationRoute = _d[0], setDestinationRoute = _d[1];
    var _e = __read(useState(defaultSudoContext.formData), 2), formData = _e[0], setFormData = _e[1];
    var _f = __read(useState(null), 2), initialSteps = _f[0], setInitialSteps = _f[1];
    var _g = __read(useState(false), 2), hasSudoBeenEstablished = _g[0], setHasSudoBeenEstablished = _g[1];
    var navigate = useNavigate();
    var location = useLocation();
    var checkSudo = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchIsSudo()];
                case 1:
                    _a.sent();
                    if (!hasSudoBeenEstablished) {
                        setHasSudoBeenEstablished(true);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    if (hasSudoBeenEstablished) {
                        setHasSudoBeenEstablished(false);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var onSetInitialSteps = useCallback(function (steps) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkSudo()];
                case 1:
                    _a.sent();
                    setInitialSteps(steps);
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var useSetInitialSteps = useCallback(function (steps) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            useEffect(function () {
                onSetInitialSteps(steps);
            }, []);
            return [2 /*return*/];
        });
    }); }, []);
    useEffect(function () {
        var hasDefaultChanged = originalRoute !== defaultSudoContext.originalRoute ||
            destinationRoute !== defaultSudoContext.destinationRoute ||
            formData !== defaultSudoContext.formData;
        if (![
            SudoRoutes.RequestOTP,
            SudoRoutes.SubmitOTP,
            originalRoute,
            destinationRoute,
        ].includes(location.pathname) &&
            hasDefaultChanged) {
            setFormData(defaultSudoContext.formData);
            setDestinationRoute(defaultSudoContext.destinationRoute);
            setOriginalRoute(defaultSudoContext.originalRoute);
            setOnEstablishedSudo(defaultSudoContext.onEstablishedSudo);
        }
    }, [location.pathname]);
    var establishSudo = useCallback(function (destRoute, onEstablishedSudoCb) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!originalRoute) {
                        setOriginalRoute(location.pathname);
                    }
                    setDestinationRoute(destRoute);
                    setOnEstablishedSudo(function () { return onEstablishedSudoCb || null; });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetchIsSudo()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, (onEstablishedSudoCb
                            ? onEstablishedSudoCb === null || onEstablishedSudoCb === void 0 ? void 0 : onEstablishedSudoCb()
                            : Promise.resolve())];
                case 3:
                    _a.sent();
                    navigate(destRoute);
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    navigate(SudoRoutes.RequestOTP);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [location, navigate]);
    var cancelSudo = useCallback(function () {
        navigate(originalRoute || "/");
    }, [navigate, originalRoute, setFormData]);
    var onFormDataChange = useCallback(function (update) {
        setFormData(function (prev) { return (__assign(__assign({}, prev), update)); });
    }, []);
    var totalSteps = initialSteps
        ? initialSteps + (hasSudoBeenEstablished ? 0 : NUMBER_OF_SUDO_STEPS)
        : null;
    return (_jsx(SudoContext.Provider, { value: {
            originalRoute: originalRoute,
            destinationRoute: destinationRoute,
            formData: formData,
            setFormData: setFormData,
            onFormDataChange: onFormDataChange,
            establishSudo: establishSudo,
            onEstablishedSudo: onEstablishedSudo,
            cancelSudo: cancelSudo,
            useSetInitialSteps: useSetInitialSteps,
            totalSteps: totalSteps,
        }, children: children }));
};
