import { useEffect } from "react";
var setGliaVisibility = function (visibility) {
    var style = visibility ? "" : "display: none !important";
    var ctaElement = document.querySelector("#salemove");
    ctaElement === null || ctaElement === void 0 ? void 0 : ctaElement.setAttribute("style", style);
};
var useSetGliaVisibility = function (visibility) {
    useEffect(function () {
        setGliaVisibility(visibility);
        return function () {
            setGliaVisibility(true);
        };
    }, [visibility, window.location.pathname]);
};
export default useSetGliaVisibility;
