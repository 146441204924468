import OrganizationUser from "byzantine/src/OrganizationUser";

import { ContextForm, Checkbox } from "cerulean";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Account from "byzantine/src/Account";
import { MODEL_STR } from "byzantine/src/Role";

import AccountCheckList from "../AccountCheckList";
import RadioSelect from "../RadioSelect";
import SectionCard from "../SectionCard";
import OrganizationAccessLevelExtraPermissions from "./OrganizationAccessLevelExtraPermissions";
import utils from "../../utils";

const AccessLevelSection = ({
  features,
  formData,
  roles,
  organizationUsers,
  errorMessage,
  onChange,
  dualApprovalRequired,
  isUsingConsumerBillpay,
}) => {
  const validateAccounts = (value, allFields) => {
    // at least one account must be selected to invite a user
    if (allFields.accessLevel === "Admin") return null;
    const hasSelectedOption = value.some((account) => account.selected);
    if (hasSelectedOption) return null;
    return "Must select at least one account";
  };

  // uncheck "All accounts" box if any internal account is unchecked
  useEffect(() => {
    const update = {};
    if (!utils.allAreSelected(formData.accounts)) {
      update[MODEL_STR.ACCOUNT] = false;
    }
    onChange(update);
  }, [formData.accounts]);

  // check all internal account when "All accounts" is selected
  useEffect(() => {
    if (formData[MODEL_STR.ACCOUNT]) {
      const newAccounts = formData.accounts.map((account) => {
        const newAccount = new Account(account);
        if (!newAccount.selected) {
          newAccount.selected = !newAccount.selected;
        }
        return newAccount;
      });
      onChange({ accounts: newAccounts });
    }
  }, [formData[MODEL_STR.ACCOUNT]]);

  return (
    <>
      <div className="section-box">
        <span className="section-title section-border nds-span-1">
          Access level
        </span>
        <ContextForm.Field required>
          <RadioSelect
            field="accessLevel"
            options={roles.map((role) => ({
              value: role.name,
              title: role.name,
              description: role.description,
            }))}
          />
        </ContextForm.Field>
      </div>
      {[
        OrganizationUser.ROLE.COLLABORATOR,
        OrganizationUser.ROLE.VIEWER,
      ].includes(formData?.accessLevel) && (
        <div className="section-box">
          <span className="section-title section-border nds-span-1">
            Access
          </span>
          <SectionCard isLoading={false} paddingSize={null}>
            <div className="padding--x--l padding--top--l">
              <SectionCard.Title text="Accounts" />
            </div>
            <div className="permission-check-list">
              <div
                className="inverted-primary-color-checkbox"
                style={{ borderBottom: "1px solid RGBA(var(--border-color))" }}
              >
                <ContextForm.Field style={{ marginBottom: "0px" }}>
                  <Checkbox
                    field={MODEL_STR.ACCOUNT}
                    label="All accounts (including future accounts)"
                  />
                </ContextForm.Field>
              </div>
              <ContextForm.Field
                required
                validate={validateAccounts}
                style={{ marginBottom: "0px" }}
              >
                <AccountCheckList
                  accessLevel={formData.accessLevel}
                  field="accounts"
                />
              </ContextForm.Field>
            </div>
          </SectionCard>
          <OrganizationAccessLevelExtraPermissions
            features={features}
            field="extraPermissions"
            organizationUsers={organizationUsers}
            formData={formData}
            error={errorMessage}
            onChange={onChange}
            dualApprovalRequired={dualApprovalRequired}
            isUsingConsumerBillpay={isUsingConsumerBillpay}
          />
        </div>
      )}
    </>
  );
};
AccessLevelSection.propTypes = {
  formData: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  organizationUsers: PropTypes.array,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  dualApprovalRequired: PropTypes.bool,
  isUsingConsumerBillpay: PropTypes.bool,
};

export default AccessLevelSection;
