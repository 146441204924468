import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { RRule } from "rrule";
import { Tabs } from "@narmi/design_system";
import ApiHttp from "byzantine/src/ApiHttp";
import BillPaySchedule from "byzantine/src/BillPaySchedule";
import Payee from "byzantine/src/Payee";
import { NotificationContext } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import SectionCard from "../SectionCard";
import TransferTable from "./TransferTable";

const sortTransfers = (transfers, accounts, accountUuid) => {
  /* separates the fetched scheduled transfers into one-time transfers and recurring transfers */
  const oneTimeT = [];
  const recurringT = [];

  transfers.forEach((t) => {
    const transfer = { ...t };
    transfer.from_account =
      accounts.find((a) => a.id === t.from_account_id) || null;
    transfer.to_account =
      accounts.find((a) => a.id === t.to_account_id) || null;

    /* only display scheduled transfers where the account is either the source or destination */
    if (
      ![transfer.from_account?.id, transfer.to_account?.id].includes(
        accountUuid,
      )
    )
      return;

    /* only display active scheduled transfers (one-time transfers are not marked as deleted when
       they are completed, and this table is for upcoming transfers)
     */
    if (transfer.state === "expired") return;

    transfer.rrule = RRule.fromString(t.recurring_rule);
    if (transfer.rrule.options.count === 1) {
      oneTimeT.push(transfer);
    } else {
      recurringT.push(transfer);
    }
  });

  return { oneTimeT, recurringT };
};

const ScheduledTransfersCard = ({ accountUuid, limits }) => {
  /* a card that displays one-time and recurring scheduled transfers/payments in separate tabs
  where the account is either the source or the destination */
  const [oneTimeTransfers, setOneTimeTransfers] = useState([]);
  const [recurringTransfers, setRecurringTransfers] = useState([]);
  const [isLoadingTransfers, setIsLoadingTransfers] = useState(true);

  const [payees, setPayees] = useState([]);
  const [payments, setPayments] = useState([]);
  const oneTimePayments = payments.filter((p) => !p.is_recurring);
  const recurringPayments = payments.filter((p) => p.is_recurring);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const { accounts } = useContext(AccountContext);
  const currentAccount = accounts.find((a) => a.id === accountUuid);
  const { sendNotification } = useContext(NotificationContext);

  const fetchScheduledTransfers = async () => {
    try {
      setIsLoadingTransfers(true);
      /* resetting the one-time and recurring transfer states because this function
         will be called again when a transfer is deleted */
      setOneTimeTransfers([]);
      setRecurringTransfers([]);
      const response = await ApiHttp.fetch(
        `transfers/scheduled?is_expired=false`,
        {
          method: "GET",
        },
      );
      if (!response?.scheduled_transfers) return;
      const { oneTimeT, recurringT } = sortTransfers(
        response.scheduled_transfers,
        accounts,
        accountUuid,
      );
      setOneTimeTransfers(oneTimeT);
      setRecurringTransfers(recurringT);
    } catch {
      sendNotification({
        type: "negative",
        text: "There was an error retrieving scheduled transfers, please try again later.",
      });
    } finally {
      setIsLoadingTransfers(false);
    }
  };

  const fetchPayees = async () => {
    try {
      setPayees(await Payee.fetchPayees());
    } catch {
      sendNotification({
        type: "negative",
        text: "Error fetching your payees. Please contact support.",
      });
    }
  };

  const fetchPayments = async () => {
    try {
      setIsLoadingPayments(true);
      const allPayments = await BillPaySchedule.fetchPayments(accountUuid);
      setPayments(BillPaySchedule.filterScheduledPayments(allPayments));
    } catch {
      sendNotification({
        type: "negative",
        text: "Error fetching your payments. Please contact support.",
      });
    } finally {
      setIsLoadingPayments(false);
    }
  };

  useEffect(() => {
    // update the `payees` field of every payment once payees and payments have been fetched
    if (payees?.length && payments?.length) {
      setPayments((prevPayments) =>
        prevPayments.map((p) => {
          p.payees = payees;
          return p;
        }),
      );
    }
  }, [JSON.stringify(payees), JSON.stringify(payments)]);

  useEffect(() => {
    fetchScheduledTransfers();
    if (currentAccount.isValidBillpaySource()) {
      fetchPayees();
      fetchPayments();
    } else {
      setIsLoadingPayments(false);
    }
  }, []);

  /* remove loading state only if both transfers and payments are done loading */
  useEffect(() => {
    if (!isLoadingPayments && !isLoadingTransfers) setIsLoading(false);
    else setIsLoading(true);
  }, [isLoadingPayments, isLoadingTransfers]);

  // only display card if there are scheduled transfers for the account
  const isCardDisplayed =
    oneTimeTransfers.length > 0 ||
    recurringTransfers.length > 0 ||
    payments.length > 0;

  // only diplay tabs if both recurring and one-time transfers exist
  const areTabsDisplayed =
    (oneTimeTransfers.length > 0 || oneTimePayments.length > 0) &&
    (recurringTransfers.length > 0 || recurringPayments.length > 0);

  const untabbedTable = (
    <TransferTable
      hasTopBorder={true}
      transfers={
        recurringTransfers.length > 0 ? recurringTransfers : oneTimeTransfers
      }
      payments={
        recurringPayments.length > 0 ? recurringPayments : oneTimePayments
      }
      accountUuid={accountUuid}
      isRecurring={
        recurringTransfers.length > 0 || recurringPayments.length > 0
      }
      limits={limits}
      fetchScheduledTransfers={fetchScheduledTransfers}
      fetchPayments={fetchPayments}
    />
  );

  const tabbedTable = (
    <Tabs defaultSelectedIndex={0}>
      <Tabs.List xPadding="l">
        <Tabs.Tab label="One time" tabId="oneTime" />
        <Tabs.Tab label="Recurring" tabId="recurring" />
      </Tabs.List>
      <Tabs.Panel tabId="oneTime">
        <div className="padding--x--l padding--bottom--xs">
          <TransferTable
            hasTopBorder={false}
            transfers={oneTimeTransfers}
            payments={oneTimePayments}
            accountUuid={accountUuid}
            isRecurring={false}
            limits={limits}
            fetchScheduledTransfers={fetchScheduledTransfers}
            fetchPayments={fetchPayments}
          />
        </div>
      </Tabs.Panel>
      <Tabs.Panel tabId="recurring">
        <div className="padding--x--l padding--bottom--xs">
          <TransferTable
            hasTopBorder={false}
            transfers={recurringTransfers}
            payments={recurringPayments}
            accountUuid={accountUuid}
            isRecurring={true}
            limits={limits}
            fetchScheduledTransfers={fetchScheduledTransfers}
            fetchPayments={fetchPayments}
          />
        </div>
      </Tabs.Panel>
    </Tabs>
  );

  return (
    <SectionCard
      isLoading={isLoading}
      paddingSize={areTabsDisplayed ? null : "l"}
      kind={areTabsDisplayed ? null : "transactions"}
    >
      {isCardDisplayed && (
        <>
          <div
            className={cc([
              "padding--bottom--s",
              {
                "padding--x--l padding--top--l": areTabsDisplayed,
              },
            ])}
          >
            <SectionCard.Title text="Scheduled transactions" />
          </div>
          {areTabsDisplayed ? tabbedTable : untabbedTable}
        </>
      )}
    </SectionCard>
  );
};

ScheduledTransfersCard.displayName = "ScheduledTransfersCard";
ScheduledTransfersCard.propTypes = {
  accountUuid: PropTypes.string.isRequired,
  limits: PropTypes.object,
};

export default ScheduledTransfersCard;
