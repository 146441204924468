var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TextInput } from "@narmi/design_system";
import TooltipTextInput from "../TooltipTextInput";
/**
 *
 * NDS TextInput configured for entering Tax ID (SSN, EIN).
 * `TaxIDTextInput` is intended to be used as a controlled component.
 * Only numeric characters are permitted,
 * and the value displayed will be formatted as the user types as XXX - XX - XXXX (or XX - XXXXXXX if the `ein` prop is `true`).
 * However, the value transmitted up to the parent via `onChange` will only include numeric characters as XXXXXXXXX
 *
 */
export var formatEin = function (value) {
    if (!value)
        return "";
    if (value.length < 2)
        return value;
    return "".concat(value.substring(0, 2), "-").concat(value.substring(2, 9));
};
export var formatSsnOrTaxID = function (value) {
    if (value.length < 3)
        return value;
    if (value.length < 5)
        return "".concat(value.substring(0, 3), "-").concat(value.substring(3, 5));
    return "".concat(value.substring(0, 3), "-").concat(value.substring(3, 5), "-").concat(value.substring(5, 9));
};
export var validateTaxID = function (value) {
    if (value && value.replace("-", "").length < 9) {
        return "Please enter a valid Tax ID/EIN";
    }
    return null;
};
function stripToTaxId(value) {
    return value.replace(/[^\d]/g, "").substring(0, 9);
}
export var MaskedTaxIDTextInput = React.forwardRef(function (_a, parentRef) {
    var isInitiallyMasked = _a.isInitiallyMasked, label = _a.label, tooltipText = _a.tooltipText, onChange = _a.onChange, value = _a.value, error = _a.error;
    var _b = __read(React.useState(isInitiallyMasked), 2), showMask = _b[0], setShowMask = _b[1];
    // const ref = React.useRef < HTMLInputElement > null;
    // React.useEffect(() => {
    //   if (isInitiallyMasked && !showMask) {
    //     ref.current?.focus();
    //   }
    // }, [isInitiallyMasked, showMask]);
    if (showMask) {
        return (_jsx(TextInput, { value: "***-**-****", label: label, readOnly: true, onFocus: function () { return setShowMask(false); } }));
    }
    return (_jsx(TaxIDTextInput, { ref: parentRef, label: label, tooltipText: tooltipText, onChange: onChange, value: value, error: error, autoComplete: "off" }));
});
var TaxIDTextInput = React.forwardRef(function (_a, forwardedRef) {
    var _b = _a.ein, ein = _b === void 0 ? false : _b, _c = _a.value, value = _c === void 0 ? "" : _c, onChange = _a.onChange, label = _a.label, _d = _a.name, name = _d === void 0 ? "tax_id" : _d, _e = _a.id, id = _e === void 0 ? "tax_id" : _e, _f = _a.tooltipText, tooltipText = _f === void 0 ? "" : _f, otherTextInputProps = __rest(_a, ["ein", "value", "onChange", "label", "name", "id", "tooltipText"]);
    var ref = React.useRef(null);
    React.useImperativeHandle(forwardedRef, function () { return ref.current; });
    var Component = tooltipText ? TooltipTextInput : TextInput;
    var conditionalProps = {};
    if (tooltipText) {
        conditionalProps.tooltipText = tooltipText;
    }
    var renderedValue = ein ? formatEin(value) : formatSsnOrTaxID(value);
    var _g = __read(React.useState(), 2), cursorPosition = _g[0], setCursorPosition = _g[1];
    React.useEffect(function () {
        if (!ref.current || cursorPosition === undefined) {
            return;
        }
        var input = ref.current;
        // if the value at the cursor is a dash, go to the next character
        var index = renderedValue.charAt(cursorPosition) === "-"
            ? cursorPosition + 1
            : cursorPosition;
        requestAnimationFrame(function () {
            input.setSelectionRange(index, index);
        });
    }, [ref.current, cursorPosition, renderedValue]);
    return (_jsx(Component, __assign({ id: id, name: name, label: label, ref: ref, value: renderedValue, inputMode: "numeric", onChange: function (event) {
            var _a;
            var index = (_a = event.target) === null || _a === void 0 ? void 0 : _a.selectionStart;
            if (index === null) {
                // this might be through automation or something, so directly set the value.
                onChange(stripToTaxId(event.target.value));
                return;
            }
            var isBackspace = event.nativeEvent.inputType ===
                "deleteContentBackward";
            if (isBackspace && renderedValue.charAt(index) === "-") {
                // the character deleted was a dash, so we need to find the dash and delete the character before it
                setCursorPosition(index - 1);
                onChange(stripToTaxId(renderedValue.substring(0, index - 1) +
                    renderedValue.substring(index)));
                return;
            }
            var isPaste = event.nativeEvent.inputType === "insertFromPaste";
            if (isPaste) {
                // if this is from a paste, don't manipulate the cursor position.
                setCursorPosition(undefined);
            }
            else {
                setCursorPosition(index);
            }
            onChange(stripToTaxId(event.target.value));
        } }, conditionalProps, otherTextInputProps)));
});
export default TaxIDTextInput;
