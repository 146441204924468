var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ContextForm } from "cerulean";
import styles from "./DrawerBottomBar.module.scss";
var DrawerBottomBar = function (_a) {
    var onSave = _a.onSave, _b = _a.saveLabel, saveLabel = _b === void 0 ? "Save" : _b, onCancel = _a.onCancel, isContextForm = _a.isContextForm, _c = _a.cancelLabel, cancelLabel = _c === void 0 ? "Cancel" : _c, _d = _a.isSaveDisabled, isSaveDisabled = _d === void 0 ? false : _d, _e = _a.isCancelDisabled, isCancelDisabled = _e === void 0 ? false : _e, _f = _a.loading, loading = _f === void 0 ? false : _f;
    var saveButton = (_jsx(Button, __assign({ kind: "primary", type: "submit", disabled: isSaveDisabled || loading, isLoading: loading }, (isContextForm ? {} : { onClick: onSave }), { children: saveLabel })));
    return (_jsxs("div", { className: styles.drawerBottomBar, children: [_jsx(Button, { kind: "negative", onClick: onCancel, disabled: isCancelDisabled || loading, children: cancelLabel }), _jsx("div", { className: styles.saveButton, children: isContextForm ? (_jsx(ContextForm.Action, { onSubmit: onSave, dangerouslyDisableShowLoading: true, children: saveButton })) : (saveButton) })] }));
};
export default DrawerBottomBar;
