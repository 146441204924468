var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line no-use-before-define
import { Button, LoadingSkeleton, RadioButtons } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useScreenStateContext } from "./contexts/ScreenStateContextProvider";
import { useSelectedDeviceContext } from "./contexts/SelectedDeviceContextProvider";
import HeaderWithSteps from "../HeaderWithSteps";
import fetchCode from "./helpers";
import SCREEN_STATES from "./constants";
var TfaChoose = function (_a) {
    var totalSteps = _a.totalSteps, step = _a.step;
    var setScreenState = useScreenStateContext().setScreenState;
    var _b = useSelectedDeviceContext(), selectedDeviceId = _b.selectedDeviceId, setSelectedDeviceId = _b.setSelectedDeviceId, setHelpMessage = _b.setHelpMessage;
    var _c = __read(React.useState(true), 2), loadingDevices = _c[0], setLoadingDevices = _c[1];
    var _d = __read(React.useState([]), 2), devices = _d[0], setDevices = _d[1];
    React.useEffect(function () {
        window.scrollTo({ top: 0, left: 0 }); // scroll to top of page (otherwise we start at the same height as where the user clicked "Review" on the prior page)
        // we expect a 403 forbidden here, we're fetching MFA devices
        ApiHttp.fetch("sudo", { method: "POST" })
            .catch(function (response) {
            var _a, _b;
            if (!((_a = response.devices) === null || _a === void 0 ? void 0 : _a.length)) {
                return;
            }
            setSelectedDeviceId((_b = response.device) === null || _b === void 0 ? void 0 : _b.id);
            setDevices(response.devices);
        })
            .finally(function () {
            setLoadingDevices(false);
        });
    }, []);
    var sendDevice = function () {
        if (!selectedDeviceId)
            return;
        fetchCode(selectedDeviceId, setHelpMessage).then(function () {
            if (setScreenState) {
                setScreenState(SCREEN_STATES.ENTER);
            }
        });
    };
    var subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    */
    _jsx("div", { style: { marginTop: "-4px" }, children: "For your security, we need to verify your identity. Tell us how you'd like to receive your code." }));
    return (_jsxs(LoadingSkeleton, { isLoading: loadingDevices, children: [_jsx(HeaderWithSteps, { step: step, totalSteps: totalSteps, headerText: "Let's verify your account", subheaderText: subheader }), (devices === null || devices === void 0 ? void 0 : devices.length) ? (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(RadioButtons, { name: "deviceSelect", options: Object.fromEntries(devices.map(function (d) { return [d.name, d.id]; })), onChange: function (e) {
                                return setSelectedDeviceId(e.target.value);
                            }, value: selectedDeviceId }) }), _jsx("div", { className: "margin--top--xl", children: _jsx(Button, { label: "Continue", onClick: sendDevice }) })] })) : (_jsx("div", { children: _jsxs("span", { children: ["Please go to your", " ", _jsx(Button, { kind: "plain", onClick: function () { return window.location.assign("/settings"); }, label: "Settings" }), " ", "and add a two factor authentication device."] }) }))] }));
};
TfaChoose.propTypes = {
    text: PropTypes.string,
};
export default TfaChoose;
