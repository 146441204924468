var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, LoadingSkeleton, RadioButtons } from "cerulean";
import { fetchSudoCode, fetchSudoDevices } from "./utils";
import HeaderWithSteps from "../HeaderWithSteps";
import { SudoRoutes, useSudoContext, NUMBER_OF_SUDO_STEPS, } from "./SudoContext";
import Container from "../Container";
import BackButton from "./BackButton";
import ActionBar from "./ActionBar";
var RequestOTP = function () {
    var _a = useSudoContext(), totalSteps = _a.totalSteps, originalRoute = _a.originalRoute;
    var navigate = useNavigate();
    var _b = __read(useState(""), 2), selectedDeviceId = _b[0], setSelectedDeviceId = _b[1];
    var _c = __read(useState(true), 2), loadingDevices = _c[0], setLoadingDevices = _c[1];
    var _d = __read(useState([]), 2), devices = _d[0], setDevices = _d[1];
    useEffect(function () {
        // We expect a 403 forbidden here when we're fetching MFA devices. A quirk of the API.
        // There are 3 uses for the API:
        // 1. Check if Sudo has been established with a GET
        // 2. Get the possible Sudo devices with a POST - this is what we're doing here it will return a 403
        // 3. Establish Sudo with a POST while passing a device ID
        fetchSudoDevices()
            .catch(function (response) {
            var _a, _b;
            if (!((_a = response.devices) === null || _a === void 0 ? void 0 : _a.length)) {
                return;
            }
            setSelectedDeviceId((_b = response.device) === null || _b === void 0 ? void 0 : _b.id);
            setDevices(response.devices);
        })
            .finally(function () {
            setLoadingDevices(false);
        });
    }, []);
    var sendDevice = function () { return __awaiter(void 0, void 0, void 0, function () {
        var message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedDeviceId) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, fetchSudoCode(selectedDeviceId)];
                case 1:
                    message = _a.sent();
                    navigate(SudoRoutes.SubmitOTP, {
                        state: { selectedDeviceId: selectedDeviceId, helpMessage: message },
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    */
    _jsx("div", { style: { marginTop: "-4px" }, children: "For your security, we need to verify your identity. Tell us how you'd like to receive your code." }));
    return (_jsx(LoadingSkeleton, { isLoading: loadingDevices, children: _jsxs(Container, { children: [_jsx(HeaderWithSteps, { step: totalSteps ? totalSteps - NUMBER_OF_SUDO_STEPS : null, totalSteps: totalSteps, headerText: "Let's verify your account", subheaderText: subheader }), (devices === null || devices === void 0 ? void 0 : devices.length) ? (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(RadioButtons, { name: "deviceSelect", options: Object.fromEntries(devices.map(function (d) { return [d.name, d.id]; })), onChange: function (e) {
                                    return setSelectedDeviceId(e.target.value);
                                }, value: selectedDeviceId }) }), _jsxs(ActionBar, { children: [_jsx(BackButton, { to: originalRoute || "/" }), _jsx(Button, { label: "Continue", onClick: sendDevice })] })] })) : (_jsx("div", { children: _jsxs("span", { "data-testid": "settings-message", children: ["Please go to your", " ", _jsx(Button, { kind: "plain", onClick: function () { return window.location.assign("/settings"); }, label: "Settings" }), " ", "and add a two factor authentication device."] }) }))] }) }));
};
export default RequestOTP;
