import ApiHttp from "byzantine/src/ApiHttp";
var fetchIsSudo = function () { return ApiHttp.fetch("sudo"); };
var fetchSudoDevices = function () { return ApiHttp.fetch("sudo", { method: "POST" }); };
var fetchSudoCode = function (selectedDeviceId) {
    // we will get a 403 forbidden here, as we're fetching an MFA code
    return ApiHttp.fetch("sudo", { method: "POST" }, { device_id: selectedDeviceId }).catch(function (response) { return ({
        message: response.message,
    }); });
};
export { fetchIsSudo, fetchSudoDevices, fetchSudoCode };
