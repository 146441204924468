var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNotificationContext, LoadingSkeleton } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";
import BankCard from "../BankCard";
import CardManagerDrawer from "../CardManagerDrawer";
import styles from "./BankCardList.module.scss";
var BankCardList = function () {
    var sendNotification = useNotificationContext().sendNotification;
    var l10n = useLocalization().l10n;
    var _a = __read(useState([]), 2), cards = _a[0], setCards = _a[1];
    var _b = __read(useState(true), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read(useState(false), 2), isCardManagerOpen = _c[0], setIsCardManagerOpen = _c[1];
    var _d = __read(useState(null), 2), selectedCardIndex = _d[0], setSelectedCardIndex = _d[1];
    useEffect(function () {
        var requestCards = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, cardsResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, ApiHttp.fetch("cards", { method: "GET", headers: { "API-Version": "0017" } }, {})];
                    case 1:
                        response = _a.sent();
                        if (!(response === null || response === void 0 ? void 0 : response.results)) {
                            return [2 /*return*/];
                        }
                        cardsResponse = response === null || response === void 0 ? void 0 : response.results.map(function (card) {
                            var cardSvg = window.btoa(card.image);
                            return __assign(__assign({}, card), { image: "data:image/svg+xml;base64,".concat(cardSvg) });
                        });
                        setCards(cardsResponse);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        sendNotification({
                            type: "negative",
                            text: l10n.getString("error-unknown", null, "Something went wrong"),
                        });
                        setCards([]);
                        return [3 /*break*/, 3];
                    case 3:
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        requestCards();
    }, []);
    var updateCardStateById = function (cardId, cardState) {
        var cardToUpdateIndex = cards.findIndex(function (card) { return card.id === cardId; });
        cards[cardToUpdateIndex] = __assign(__assign({}, cards[cardToUpdateIndex]), { state: cardState });
        setCards(__spreadArray([], __read(cards), false));
    };
    return (_jsxs(LoadingSkeleton, { isLoading: isLoading, children: [_jsx("div", { className: styles.cardList, children: cards.map(function (card, index) { return (_jsx("div", { className: styles.cardContainer, children: _jsx(BankCard, { textColor: card.text_color, isLocked: card.state === "locked", image: card.image, name: card.name, lastFourDigits: card.last_four_digits, network: card.network, state: card.state, onManageClick: function () {
                            setSelectedCardIndex(index);
                            setIsCardManagerOpen(true);
                        } }) }, card.id)); }) }), _jsx(CardManagerDrawer, { isOpen: isCardManagerOpen, onUserDismiss: function () { return setIsCardManagerOpen(false); }, card: typeof selectedCardIndex === "number"
                    ? cards[selectedCardIndex]
                    : null, updateCardStateById: updateCardStateById })] }));
};
export default BankCardList;
