var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Localized } from "@fluent/react";
import PropTypes from "prop-types";
import { Menu } from "react-feather";
import { Button, LoadingShim } from "@narmi/design_system";
import { InstitutionLogo, useNotificationContext, useLoadingContext, } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import ButtonBar from "./ButtonBar";
import Modal from "./Modal";
import Details from "./Details";
import ProfileSwitcher from "./profile_switcher/ProfileSwitcher";
import { useCurrentUser } from "./contexts/CurrentUserContext";
var NavBar = function (_a) {
    var features = _a.features, children = _a.children, deviceIsRemembered = _a.deviceIsRemembered, institution = _a.institution;
    var currentUser = useCurrentUser().currentUser;
    var _b = __read(useState(false), 2), open = _b[0], setOpen = _b[1];
    // this is to fetch linked profiles in the user details modal
    var _c = __read(useState(false), 2), isFetchingProfiles = _c[0], setIsFetchingProfiles = _c[1];
    var _d = __read(useState([]), 2), linkedProfiles = _d[0], setLinkedProfiles = _d[1];
    var sendNotification = useNotificationContext().sendNotification;
    var setIsLoading = useLoadingContext().setIsLoading;
    var profileSwitcherEnabled = features.profile_switcher && deviceIsRemembered;
    useEffect(function () {
        var fetchProfiles = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, allLinkedProfiles, isOnlyCurrentUser, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsFetchingProfiles(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ApiHttp.fetch("/linked_profiles", {
                                method: "GET",
                                endpointIsUrl: true,
                            })];
                    case 2:
                        response = _b.sent();
                        if (response === null || response === void 0 ? void 0 : response.linked_profiles) {
                            allLinkedProfiles = response === null || response === void 0 ? void 0 : response.linked_profiles;
                            isOnlyCurrentUser = allLinkedProfiles.length === 1 &&
                                allLinkedProfiles[0].username === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.username);
                            setLinkedProfiles(isOnlyCurrentUser ? [] : allLinkedProfiles);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        sendNotification({
                            type: "negative",
                            text: "There was an error retrieving your linked profiles, please try again later.",
                        });
                        return [3 /*break*/, 4];
                    case 4:
                        setIsFetchingProfiles(false);
                        return [2 /*return*/];
                }
            });
        }); };
        if (profileSwitcherEnabled) {
            fetchProfiles();
        }
    }, [profileSwitcherEnabled, currentUser, sendNotification]);
    var toggleOpen = function () { return setOpen(!open); };
    var handleKeyUp = function (event) {
        if (event.key === "Enter") {
            toggleOpen();
        }
    };
    return (_jsx("div", { className: "navBar", children: _jsxs("div", { className: "nds-container narmi-grid", children: [_jsx(InstitutionLogo, { institution: institution, className: "nds-navbar-logo" }), _jsx(Modal, { open: open, setOpen: setOpen, type: "right", children: _jsxs("div", { className: "nds-navbar-container", "data-testid": "navbar-container", children: [_jsx(ButtonBar, { className: "nds-navbar-mainmenu", children: children }), currentUser && (_jsx(ButtonBar, { className: "nds-navbar-usermenu", children: _jsxs(Details, { summary: _jsx(Button, { kind: "menu", label: currentUser.getShortDescription() }), type: linkedProfiles.length > 0 ? "medium details" : "details", children: [_jsx(LoadingShim, { isLoading: isFetchingProfiles, children: linkedProfiles.length > 0 && (_jsx("div", { className: "padding--bottom--l", "data-testid": "linked-profiles-container", children: linkedProfiles.map(function (profile, index) { return (_jsx("div", { className: "profile-hover", children: _jsx(ProfileSwitcher, { profile: profile }) }, index)); }) })) }), profileSwitcherEnabled && (_jsx("button", { className: "profile-option button--reset", "data-testid": "link-profile-button", onClick: function () {
                                                setIsLoading(true);
                                                window.open("/login/link", "_self");
                                            }, children: "Link another profile" })), _jsx("button", { onClick: function () { return window.open("/settings", "_self"); }, className: "profile-option button--reset", "data-testid": "go-to-settings-button", children: "Settings" }), deviceIsRemembered && (_jsx("button", { className: "profile-option button--reset", onClick: function () { return window.open("/logout/forget", "_self"); }, "data-testid": "forget-this-device-button", children: "Forget this device" })), _jsx("button", { className: "profile-option button--reset", onClick: function () { return window.open("/logout", "_self"); }, "data-testid": "logout-button", children: "Logout" })] }) }))] }) }), currentUser ? (_jsx("div", { role: "button", tabIndex: 0, className: "nds-navbar-hamburger", onKeyUp: handleKeyUp, onClick: toggleOpen, children: _jsx(Menu, {}) })) : (!window.location.pathname.match(/password_reset|enroll|login\/verify_/) && (_jsxs("div", { className: "get-started", children: [_jsxs("span", { children: [_jsx(Localized, { id: "header-get-started", children: "Don't have a digital banking account?" }), _jsx("span", { children: "\u00A0" })] }), _jsx(Button, { kind: "plain", label: _jsx(Localized, { id: "cta-get-started", children: "Get started" }), onClick: function () {
                                window.location.assign("/enroll");
                            } })] })))] }) }));
};
NavBar.propTypes = {
    features: PropTypes.object,
    children: PropTypes.node.isRequired,
    deviceIsRemembered: PropTypes.bool,
    institution: PropTypes.object,
};
export default NavBar;
