import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentCard, Button, catcat as cc } from "cerulean";
import { useMarketingTarget } from "byzantine/src/dbbl/businessLogic/modules/marketing/marketing.container";
import utils from "byzantine/src/utils";
var MarketingCard = function (_a) {
    var address = _a.address;
    var _b = useMarketingTarget(address), loading = _b.loading, config = _b.config, linkUrl = _b.linkUrl;
    if (loading || !config || !linkUrl) {
        return null;
    }
    var title = config.title, body = config.body, icon = config.icon, actionText = config.linkText;
    return (_jsx("div", { className: "margin--bottom--l", children: _jsxs(ContentCard, { kind: "elevated", children: [_jsxs("div", { className: "fontColor--theme--primary fontSize--l  margin--bottom--s fontWeight--bold alignChild--left-center", children: [_jsx("span", { className: cc([
                                utils.ndsIconPrepend(icon),
                                "margin--right--xs",
                                "fontSize--heading2",
                            ]) }), _jsx("div", { children: title })] }), _jsx("p", { className: "margin--bottom--s", children: body }), _jsx(Button, { as: "a", href: linkUrl, kind: "plain", ariaLabel: "".concat(title, " - ").concat(actionText), label: actionText || "Learn more", rel: "noreferrer", target: "_blank" })] }) }));
};
export default MarketingCard;
