var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { catcat as cc, Tag } from "cerulean";
import VISA_LOGO from "../../../../assets/images/visa_logo_official.svg";
import MASTERCARD_LOGO from "../../../../assets/images/mastercard_logo_unofficial.svg";
import styles from "./CardImage.module.scss";
var getCardStyle = function (network, size) {
    if (size === void 0) { size = "small"; }
    var CARD_STYLES = {
        Mastercard: {
            aspectRatio: 152 / 108,
            logo: MASTERCARD_LOGO,
            bottom: size === "small" ? "7px" : "8px",
            right: "0px",
        },
        Visa: {
            aspectRatio: 1920 / 620,
            logo: VISA_LOGO,
            bottom: size === "small" ? "11.65px" : "18.01px",
            right: size === "small" ? "12px" : "20px",
        },
    };
    if (network in CARD_STYLES) {
        return CARD_STYLES[network];
    }
    return CARD_STYLES.Visa;
};
var CardImage = function (_a) {
    var _b, _c, _d;
    var name = _a.name, textColor = _a.textColor, isLocked = _a.isLocked, image = _a.image, lastFourDigits = _a.lastFourDigits, _e = _a.size, size = _e === void 0 ? "small" : _e, network = _a.network;
    var _f = getCardStyle(network, size), aspectRatio = _f.aspectRatio, logo = _f.logo, remainingStyleProps = __rest(_f, ["aspectRatio", "logo"]);
    var width = size === "small" ? "32px" : "68px";
    var height = "".concat(parseInt(width.split("px")[0], 10) / aspectRatio, "px");
    return (_jsx("div", { className: cc(styles.cardWrapper, (_b = {},
            _b[styles.large] = size === "large",
            _b)), children: _jsxs("div", { className: cc(styles.cardContainer, (_c = {}, _c[styles.large] = size === "large", _c), (_d = {}, _d[styles.small] = size === "small", _d)), children: [_jsxs("div", { className: styles.cardSvg, style: {
                        color: textColor,
                        filter: isLocked ? "opacity(0.4)" : "",
                        backgroundImage: "url('".concat(image, "')"),
                    }, children: [name && _jsx("span", { className: styles.cardName, children: name }), _jsx("span", { className: styles.mask, children: "**" }), _jsx("span", { className: styles.lastFour, children: lastFourDigits }), _jsx("div", { className: styles.cardLogo, style: __assign({ backgroundImage: "url('".concat(logo, "')"), width: width, height: height }, remainingStyleProps) })] }), isLocked && (_jsx("div", { className: styles.lockedTag, children: _jsx(Tag, { label: "Locked" }) }))] }) }));
};
export default CardImage;
