import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { ContentCard, IconButton, useNotificationContext } from "cerulean";
import { Link } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import styles from "./ReplaceCard.module.scss";
var ReplaceCard = function (_a) {
    var cardId = _a.cardId, state = _a.state, onUserDismiss = _a.onUserDismiss;
    var l10n = useLocalization().l10n;
    var sendNotification = useNotificationContext().sendNotification;
    var onDamagedReplacementClick = useCallback(function () {
        sendNotification({
            type: "negative",
            text: l10n.getString("card-feature-replace-damaged-error"),
        });
        onUserDismiss();
    }, []);
    var isDamaged = state === "damaged";
    var replaceCardContent = (_jsxs(ContentCard, { kind: "elevated", children: [_jsxs("div", { className: styles.titleContainer, children: [_jsx("span", { className: styles.title, children: l10n.getString("card-feature-replace") }), _jsx("span", { "aria-hidden": "true", className: styles.iconContainer, children: _jsx(IconButton, { name: "arrow-right", kind: "plain", textSize: "l" }) })] }), _jsx("p", { className: "fontColor--secondary fontSize--s ".concat(styles.description), children: l10n.getString("card-feature-replace-description") })] }));
    return !isDamaged ? (_jsx(Link, { to: "/cards/replace-a-card/".concat(cardId, "/reason"), className: styles.replaceCardContainer, children: replaceCardContent })) : (_jsx("div", { onClick: onDamagedReplacementClick, className: styles.replaceCardContainer, "aria-label": "Replace Card - Replacing a recently replaced damaged card is not allowed.", children: replaceCardContent }));
};
export default ReplaceCard;
