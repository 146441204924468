import { useEffect } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { selectUser } from "../../businessLogic/entities/selectors/user.selectors";
import { usePrevious } from "../../hooks";
var requestPromisesCache = new Map();
var getCacheKey = function (url, options) {
    if (options.method !== "GET" || options.disableCaching === true) {
        return -1;
    }
    var cacheKey = "".concat(options.method, ":").concat(url, ":auth=").concat(!options.disableAuth);
    return cacheKey;
};
function addItem(cacheKey, response) {
    if (cacheKey === -1) {
        return;
    }
    requestPromisesCache.set(cacheKey, {
        promise: response,
        createdAt: DateTime.now(),
    });
}
function checkCache(cacheKey, ttlMilliseconds) {
    if (cacheKey === -1) {
        return false;
    }
    var now = DateTime.now();
    var cacheItem = requestPromisesCache.get(cacheKey);
    if (!cacheItem) {
        return false;
    }
    var timeDelta = now.diff(cacheItem.createdAt).milliseconds;
    if (timeDelta < ttlMilliseconds) {
        return cacheItem.promise;
    }
    requestPromisesCache.delete(cacheKey);
    return false;
}
function clearCache() {
    requestPromisesCache.clear();
}
export var useInvalidateRequestCache = function () {
    var user = useSelector(selectUser);
    var previousUser = usePrevious(user);
    var userId = user === null || user === void 0 ? void 0 : user.id;
    var previousUserId = previousUser === null || previousUser === void 0 ? void 0 : previousUser.id;
    useEffect(function () {
        if (userId === previousUserId) {
            return;
        }
        clearCache();
    }, [userId, previousUserId]);
};
export default {
    getCacheKey: getCacheKey,
    addItem: addItem,
    checkCache: checkCache,
    useInvalidateRequestCache: useInvalidateRequestCache,
};
