import Filters from "./filters";
import ApiHttp from "./ApiHttp";

export const isABalance = (balance) =>
  !Number.isNaN(Number(balance)) && balance !== null;

/*
See https://github.com/narmi/banking/blob/e7a51cd39cbb99ea15ee25826dccb3f5b7cb8185/indigo/services/search.py#L245-L250
*/
export const SearchTransactionTypeOptions = [
  { displayName: "Debit", value: "deposit" },
  { displayName: "Credit", value: "credit" },
];

export default class Transaction {
  constructor(props) {
    this.account = props.account;
    this.amount = props.amount;
    this.category = props.category;
    this.check = props.check || "";
    this.creation_date = props.creation_date;
    this.description = props.description;
    this.raw_description = props.raw_description;
    this.id = props.id;
    this.resulting_balance = props.resulting_balance;
    this.location = props.location || {};
    this.merchant = props.merchant;
    this.note = props.note;
    this.posted_date = props.posted_date;
    this.overview = props.overview;
    this.isPending = !this.posted_date;
    this.source = props.source;
    this.tags = props.tags;
    this.type = props.type || "";
    this.raw = props.raw || {};
  }

  getTransactionImage(fetchApiWithAuth = null) {
    const url = `transactions/${this.id}/images`;
    const method = "GET";

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url, { method });
    }
    return ApiHttp.fetch(url, { method });
  }

  update(payload, fetchApiWithAuth = null) {
    const url = `transactions/${this.id}`;
    const method = "PUT";

    if (fetchApiWithAuth) {
      return fetchApiWithAuth(url, { method, payload });
    }
    return ApiHttp.fetch(url, { method }, payload);
  }

  /**
   * Factory method returning a new instance of Transaction from
   * an indigo.serialized Transaction
   * */
  static deserialize(payload, account) {
    return new Transaction({
      ...payload,
      account,
      creation_date: Filters.americanDate(payload.created_at),
      // `amount` & `resulting_balance` are both in pennies
      // since byzantine doesn't import NDS, so we must use
      // NDS's formatter function in azul for rendering
      resulting_balance: payload.ledger_balance,
      posted_date: payload.settled_at
        ? Filters.americanDate(payload.settled_at)
        : "",
      overview: {
        description: Filters.removeExtraSpaces(payload.description),
        amount: Filters.currency(payload.amount, { hasSign: true }),
        resulting_balance: isABalance(payload.ledger_balance)
          ? Filters.currency(payload.ledger_balance)
          : "--",
        key_date:
          payload.settled_at || payload.created_at
            ? Filters.longMonthDayYear(payload.settled_at || payload.created_at)
            : "",
        postedDate: payload.settled_at
          ? Filters.longMonthDayYear(payload.settled_at)
          : "",
        createdDate: payload.created_at
          ? Filters.longMonthDayYear(payload.created_at)
          : "",
        raw_description: Filters.removeExtraSpaces(payload.raw_description),
        principal: Filters.currency(
          payload.metadata?.spread_details?.value?.principal_amount,
          {
            hasDecimal: true,
          }
        ),
        interest: Filters.currency(
          payload.metadata?.spread_details?.value?.interest_amount,
          {
            hasDecimal: true,
          }
        ),
        escrow: Filters.currency(
          payload.metadata?.spread_details?.value?.loan_escrow_amount,
          {
            hasDecimal: true,
          }
        ),
        fees: Filters.currency(
          payload.metadata?.spread_details?.value?.fees_amount,
          {
            hasDecimal: true,
          }
        ),
        feesLate: Filters.currency(
          payload.metadata?.spread_details?.value?.loan_hist_late_fee_amount,
          { hasDecimal: true }
        ),
        feesNotLate: Filters.currency(
          payload.metadata?.spread_details?.value
            ?.loan_hist_non_late_fee_amount,
          { hasDecimal: true }
        ),
        suspense: Filters.currency(
          payload.metadata?.spread_details?.value?.suspense_amount,
          {
            hasDecimal: true,
          }
        ),
      },
      raw: payload,
    });
  }

  setResultingBalance(balance) {
    if (isABalance(balance)) {
      this.resulting_balance = balance;
      if (this.overview) {
        this.overview.resulting_balance = Filters.currency(balance);
      }
    }
  }

  static calculatePendingBalances(transactions) {
    /***
    Used to estimate resulting_balance for an array of pending and posted transactions
    ***/
    const mutatedTransactions = transactions.slice();
    let previousResultingBalance = undefined;
    for (let i = mutatedTransactions.length - 1; i >= 0; i--) {
      const transaction = mutatedTransactions[i];
      if (
        transaction.isPending &&
        typeof previousResultingBalance !== "undefined"
      ) {
        transaction.setResultingBalance(
          Number(previousResultingBalance + transaction.amount)
        );
      }
      previousResultingBalance = transaction.resulting_balance;
    }
    return mutatedTransactions;
  }

  /**
   * Sorts an array of Transactions in place by posted date and returns it.
   * Pending transactions appear on top.
   * @param {Transaction[]} transactions
   */
  static sortByPostedDate(transactions) {
    return transactions.sort((a, b) => {
      if (!a.posted_date && b.posted_date) return -1;
      if (a.posted_date && !b.posted_date) return 1;
      return b.id.localeCompare(a.id);
    });
  }
}
