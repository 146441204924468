import React from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { formatNumber } from "cerulean";

const AmountSavedCard = ({ hasBeenEnrolled, totalSaved }) => {
  const { l10n } = useLocalization();
  return (
    <div className="amount-saved-card-wrapper">
      {hasBeenEnrolled ? (
        <>
          <div
            className="fontSize--l margin--bottom--m"
            data-testid="saved-text"
          >{l10n.getString("auto-savings-total-saved")}</div>
          <h1 data-testid="saved-amount-text">{`${formatNumber(
            totalSaved / 100
          )}`}</h1>
        </>
      ) : (
        <>
          <h4>{l10n.getString("heading-enable-auto-savings")}</h4>
          <div className="margin--top--m">
            {l10n.getString("enable-auto-savings-average")}
          </div>
        </>
      )}
    </div>
  );
};
AmountSavedCard.propTypes = {
  hasBeenEnrolled: PropTypes.bool.isRequired,
  totalSaved: PropTypes.number,
};
export default AmountSavedCard;
