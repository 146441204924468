var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { Button, Dialog, useLoadingContext } from "cerulean";
import { useInstitutionSettings, InstitutionSettingsContextProvider } from "./contexts/InstitutionSettingsContext";
import styles from "./OAuth2Authorize.module.scss";
var OAuth2AuthorizeComponent = function (_a) {
    var name = _a.name, scopes = _a.scopes;
    var l10n = useLocalization().l10n;
    var setIsLoading = useLoadingContext().setIsLoading;
    var institutionSettings = useInstitutionSettings();
    useEffect(function () {
        setIsLoading(true);
        if (institutionSettings.long_name) {
            setIsLoading(false);
        }
    }, [institutionSettings.long_name]);
    var onUserDismiss = function () {
        var _a;
        (_a = document.getElementById("cancel-button")) === null || _a === void 0 ? void 0 : _a.click();
    };
    var handleAuthorizeClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            (_a = document.getElementById("authorize-button")) === null || _a === void 0 ? void 0 : _a.click();
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(Dialog, { isOpen: true, onUserDismiss: onUserDismiss, title: l10n.getString("dialog-title-authorize-oauth2", { appName: name }), footer: _jsxs("div", { className: styles.authorizeButtons, children: [_jsx(Button, { kind: "negative", onClick: onUserDismiss, label: l10n.getString("button-cancel") }), _jsx(Button, { onClick: handleAuthorizeClick, label: l10n.getString("button-authorize") })] }), children: [_jsxs("div", { className: styles.authorizeAppHeader, children: [_jsx("div", { className: styles.genericBankLogo, children: _jsx("span", { className: "narmi-icon-bank ".concat(styles.icon) }) }), _jsxs("div", { className: styles.appAccountAccess, children: [name, " wants to access your ", _jsx("span", { className: styles.institutionName, children: institutionSettings.long_name }), " account(s)."] })] }), _jsxs("div", { className: styles.appPermissions, children: ["This application requires the following permissions:", _jsx("ul", { children: scopes.map(function (scope) { return (_jsx("li", { children: scope }, scope)); }) })] })] }));
};
var OAuth2Authorize = function (_a) {
    var name = _a.name, scopes = _a.scopes;
    return (_jsx(InstitutionSettingsContextProvider, { children: _jsx(OAuth2AuthorizeComponent, { name: name, scopes: JSON.parse(scopes) }) }));
};
export default OAuth2Authorize;
