import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { featureEquals } from "byzantine/src/Feature";

import { WireTransfer } from "../../src";
import { AccountContextProvider } from "../contexts/AccountContext";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import { UserFeaturesContextProvider } from "../contexts/UserFeaturesContext";
import TransferMenu from "./TransferMenu";
import SimpleTransferRouter from "./SimpleTransferRouter";
import BillPay from "./BillPay";
import M2MTransfer from "./M2MTransfer";
import JhaZelle from "./JhaZelle";
import GenericZelle, { ZelleUrl } from "./GenericZelle";
import CustomizableTransferMenu from "./CustomizableTransferMenu";
import LoanPaymentRouter from "./loans/LoanPaymentRouter";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";

const TransferPageContainer = ({ accounts, limitsJson, features }) => {
  const limits = JSON.parse(limitsJson);

  return (
    <AccountContextProvider accounts={[...accounts]}>
      <UserFeaturesContextProvider>
        <InstitutionSettingsContextProvider>
          <Router basename="/transfer">
            <ScrollToTopOnRouteChange />
            <Routes>
              <Route
                path="/funds"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-page">
                      <SimpleTransferRouter limits={limits} />
                    </div>
                  </div>
                }
              />
              <Route
                path="/bill_pay"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-page">
                      <BillPay />
                    </div>
                  </div>
                }
              />
              <Route
                path="/m2m"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-page">
                      <M2MTransfer />
                    </div>
                  </div>
                }
              />
              <Route
                path="/wires/*"
                element={<WireTransfer limits={limits} />}
              />
              <Route
                path="/zelle"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-page">
                      {(featureEquals(features, "zelle", true) ||
                        featureEquals(features, "zelle", "jha")) && (
                        <JhaZelle />
                      )}
                      {featureEquals(features, "zelle", "fis") && <GenericZelle url={ZelleUrl.FIS} />}
                      {featureEquals(features, "zelle", "fiserv") && <GenericZelle url={ZelleUrl.FISERV} />}
                    </div>
                  </div>
                }
              />
              <Route
                path="/loans"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-page">
                      <LoanPaymentRouter limits={limits} />
                    </div>
                  </div>
                }
              />
              <Route
                path="/"
                element={
                  <div className="transfer-page-layout">
                    <div className="transfer-menu">
                      {features.beta_naf_transfer_option ? (
                        <CustomizableTransferMenu />
                      ) : (
                        <TransferMenu />
                      )}
                    </div>
                  </div>
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </InstitutionSettingsContextProvider>
      </UserFeaturesContextProvider>
    </AccountContextProvider>
  );
};

TransferPageContainer.propTypes = {
  accounts: PropTypes.array,
  institution: PropTypes.object,
  payees: PropTypes.array,
  limitsJson: PropTypes.string,
  features: PropTypes.object,
};

export default TransferPageContainer;
