import ApiHttp from "byzantine/src/ApiHttp";

const POLICY_URL = "thread_files/policies";

export const openFileDialog = () => new Promise((resolve) => {
    const fileElement = document.createElement("input");
    fileElement.type = "file";
    fileElement.addEventListener("change", (e) => {
      const file = e.target.files[0] || null;
      resolve(file);
    });
    const cancelHandler = () => {
      if (fileElement.value.length === 0) {
        setTimeout(() => {
          if (fileElement.files.length === 0) {
            resolve(null);
          }
        }, 500);
      }
      document.body.removeEventListener("focus", cancelHandler, true);
    };
    document.body.addEventListener("focus", cancelHandler, true);
    fileElement.click();
  });

const createUploadPolicy = (file) => {
  const payload = { type: file.type, name: file.name };
  return ApiHttp.fetch(POLICY_URL, { method: "POST" }, payload);
};

export const getUrlFromS3XMLResponse = (xml) => {
  const tree = new DOMParser().parseFromString(xml, "text/xml");
  const tag = tree.getElementsByTagName("Location")[0];
  const url = decodeURIComponent(tag.childNodes[0].nodeValue);
  return url;
};

export const uploadFile = async (file) => {
  const { form_action: url, ...data } = await createUploadPolicy(file);

  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  formData.append("file", file);

  const xmlData = await ApiHttp.fetch(
    url,
    { method: "POST", endpointIsUrl: true },
    formData
  );
  return getUrlFromS3XMLResponse(xmlData);
};

export default {
  openFileDialog,
  uploadFile,
};
