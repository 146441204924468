import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import { modules } from "byzantine";
import WireTransferRedesign from "../../../../components/transfer/wires/WireTransfer";
import WireTemplatesScreen from "./templates/templates.screen";
function WireTransfer(_a) {
    var limits = _a.limits;
    var fedwireTemplatesEnabled = modules.wireTemplates.useWirePermissions().fedwireTemplatesEnabled;
    if (fedwireTemplatesEnabled) {
        return _jsx(WireTemplatesScreen, {});
    }
    return _jsx(WireTransferRedesign, { limits: limits });
}
export default memo(WireTransfer);
