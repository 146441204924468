import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLoadingContext, useNotificationContext } from "cerulean";
import utils from "../../utils";
var BillPaySsoRedirect = function () {
    var setIsLoading = useLoadingContext().setIsLoading;
    var sendNotification = useNotificationContext().sendNotification;
    var formRef = useRef(null);
    var p1InputRef = useRef(null);
    var navigate = useNavigate();
    var handleSsoSuccess = function (_a) {
        var url = _a.url, session = _a.session;
        if (!(formRef === null || formRef === void 0 ? void 0 : formRef.current) || !(p1InputRef === null || p1InputRef === void 0 ? void 0 : p1InputRef.current)) {
            return;
        }
        window.history.replaceState({}, "", "/transfer");
        formRef.current.action = url;
        p1InputRef.current.value = session;
        formRef.current.submit();
    };
    var handleSsoError = function (error) {
        var defaultErrorMessage = "There was an error, please try again shortly.";
        var extractedError = utils.extractErrorMessage(error, defaultErrorMessage);
        navigate("/transfer", { replace: true });
        sendNotification({ type: "negative", text: extractedError });
    };
    var getSso = function () {
        setIsLoading(true);
        ApiHttp.fetch("signed_urls/bill_pay/")
            .then(handleSsoSuccess)
            .catch(handleSsoError);
    };
    useEffect(function () {
        getSso();
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("form", { ref: formRef, id: "ipay-sso-form", hidden: true, method: "POST", children: [_jsx("input", { ref: p1InputRef, id: "ipay-p1-input", type: "hidden", name: "p1" }), _jsx("input", { type: "hidden", name: "op", value: "Login" }), _jsx("input", { type: "hidden", name: "method", value: "Session" })] }) }));
};
export default BillPaySsoRedirect;
