// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".replaceCardContainer--YSvof{display:block;cursor:pointer}.replaceCardContainer--YSvof div.nds-contentCard{height:86px}.replaceCardContainer--YSvof .titleContainer--VLog7{display:flex;justify-content:space-between;color:var(--font-color-primary)}.replaceCardContainer--YSvof .titleContainer--VLog7 .title--CN78B{font-weight:var(--font-weight-semibold)}.replaceCardContainer--YSvof .titleContainer--VLog7 .iconContainer--x_ZDU{margin-top:-4px;margin-right:-4px;display:inline-block;color:var(--font-color-heading)}.replaceCardContainer--YSvof .titleContainer--VLog7 .iconContainer--x_ZDU span.narmi-icon-arrow-right{font-size:24px}.replaceCardContainer--YSvof .description--CpEXm{color:var(--font-color-secondary);padding-top:var(--space-xs)}", "",{"version":3,"sources":["webpack://./components/settings/cards/CardManagerDrawer/ReplaceCard/ReplaceCard.module.scss"],"names":[],"mappings":"AAAA,6BACE,aAAA,CACA,cAAA,CAEA,iDACE,WAAA,CAGF,oDACE,YAAA,CACA,6BAAA,CACA,+BAAA,CAEA,kEACE,uCAAA,CAGF,0EACE,eAAA,CAIA,iBAAA,CACA,oBAAA,CACA,+BAAA,CALA,sGACE,cAAA,CAQN,iDACE,iCAAA,CACA,2BAAA","sourcesContent":[".replaceCardContainer {\n  display: block;\n  cursor: pointer;\n\n  div:global(.nds-contentCard) {\n    height: 86px;\n  }\n\n  .titleContainer {\n    display: flex;\n    justify-content: space-between;\n    color: var(--font-color-primary);\n\n    .title {\n      font-weight: var(--font-weight-semibold);\n    }\n\n    .iconContainer {\n      margin-top: -4px;\n      span:global(.narmi-icon-arrow-right) {\n        font-size: 24px;\n      }\n      margin-right: -4px;\n      display: inline-block;\n      color: var(--font-color-heading);\n    }\n  }\n\n  .description {\n    color: var(--font-color-secondary);\n    padding-top: var(--space-xs);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"replaceCardContainer": "replaceCardContainer--YSvof",
	"titleContainer": "titleContainer--VLog7",
	"title": "title--CN78B",
	"iconContainer": "iconContainer--x_ZDU",
	"description": "description--CpEXm"
};
export default ___CSS_LOADER_EXPORT___;
