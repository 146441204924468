import { Localized, useLocalization } from "@fluent/react";
import { Button, TextInput } from "@narmi/design_system";
import {
  ContextForm,
  NotificationContext,
  PasswordTextInput,
  useFormData,
  CsrfTokenMiddleware,
  jsonFromDocument,
} from "cerulean";
import indefinite from "indefinite";
import PropTypes from "prop-types";
import React from "react";
import ReCAPTCHAFormField from "../form/ReCAPTCHAFormField";
import "./authentication.css";

const LoginPageContainer = ({
  deviceEnrolled,
  institutionShortName,
  institution,
  recaptchaPublicKey,
}) => {
  const { sendNotification } = React.useContext(NotificationContext);
  const { formData, onChange } = useFormData();
  const { l10n } = useLocalization();
  const linkProfileUrl = window.location.pathname.match("/login/link");

  const showCaptcha = JSON.parse(
    document.getElementById("show_captcha").textContent
  );
  const authUsername = JSON.parse(
    document.getElementById("auth_username").textContent
  );

  const linkedProfileUsername = JSON.parse(
    document.getElementById("new_username").textContent
  );

  const formRedirectUrl = JSON.parse(
    document.getElementById("next").textContent
  );

  const form = React.useRef(null);

  React.useEffect(() => {
    const formErrors = jsonFromDocument("form_non_field_errors", "[]");
    if (formErrors.length) {
      sendNotification({ type: "negative", text: formErrors[0] });
    }
    let username = jsonFromDocument("username", "");
    if (linkedProfileUsername !== null) {
      username = linkedProfileUsername;
    }

    if (username) {
      onChange({ username });
    }
  }, []);

  const onBlur = () => {
    if (formData.username) {
      const username = formData.username.trim();
      onChange({ username });
    }
  };

  const institutionUserType =
    institution.type === "bank"
      ? l10n.getString("user-type-bank", null, "customer")
      : l10n.getString("user-type-credit-union", null, "member");

  const header = () => {
    if (deviceEnrolled && !linkProfileUrl) {
      return l10n.getString("heading-welcome-back");
    }
    if (linkedProfileUsername) {
      return l10n.getString("sign-in");
    }
    return l10n.getString("heading-welcome");
  };

  const subHeader = () => {
    if (linkedProfileUsername) {
      return l10n.getString("enter-password");
    }
    return l10n.getString(
      "enter-profile-credentials",
      null,
      "Enter the credentials for the profile you would like to link."
    );
  };

  const queryParams = new URLSearchParams(window.location.search);
  const queryParamRedirectUrl = queryParams.get("next")
    ? encodeURIComponent(queryParams.get("next"))
    : "/";

  return (
    <div className="authentication-container">
      <div className="mobile-web-extra-space">
        <h1>{header()}</h1>
        {linkProfileUrl && <div className="margin--top--xs">{subHeader()}</div>}
        {!deviceEnrolled && (
          <div className="authentication-help-text">
            <Localized
              id="helptext-get-started"
              vars={{
                institutionName: indefinite(institutionShortName),
                userType: institutionUserType,
              }}
            >
              {`If you're ${indefinite(
                institutionShortName
              )} ${institutionUserType} but have never logged into digital
            banking, click "Get started"`}
            </Localized>
          </div>
        )}
      </div>
      <ContextForm
        ref={form}
        data={formData}
        onChange={onChange}
        action={`/login`}
        autoComplete="off"
        method="post"
      >
        <span className={linkedProfileUsername ? "disabled-text-box" : ""}>
          <ContextForm.Field required readonly>
            <TextInput
              name="username"
              field="username"
              label={
                authUsername
                  ? l10n.getString("label-username", null, "Username")
                  : l10n.getString("label-email", null, "Email")
              }
              readOnly={linkedProfileUsername}
              onBlur={onBlur}
            />
          </ContextForm.Field>
        </span>

        <ContextForm.Field required>
          <PasswordTextInput field="password" />
        </ContextForm.Field>
        <ReCAPTCHAFormField
          showCaptcha={showCaptcha}
          recaptchaPublicKey={recaptchaPublicKey}
        />
        <ContextForm.Field>
          <input
            type="hidden"
            id="next"
            name="next"
            value={formRedirectUrl || decodeURIComponent(queryParamRedirectUrl)}
          />
        </ContextForm.Field>

        <CsrfTokenMiddleware />
        <ContextForm.ActionBar>
          <ContextForm.Action
            onSubmit={(_, e) => {
              e.preventDefault();
              form.current.submit();
            }}
          >
            <Button
              as="button"
              label={l10n.getString("button-login", null, "Log in")}
            />
          </ContextForm.Action>
          <ContextForm.Action
            onSubmit={() => {
              window.location.assign("/password_reset");
            }}
            noValidation
          >
            <Button
              kind="plain"
              type="button"
              data-test-element="button-forgot-password"
              label={l10n.getString(
                "button-forgot-credentials",
                null,
                "Forgot your username or password?"
              )}
            />
          </ContextForm.Action>
        </ContextForm.ActionBar>
      </ContextForm>
    </div>
  );
};
LoginPageContainer.propTypes = {
  deviceEnrolled: PropTypes.bool,
  institutionShortName: PropTypes.string,
  institution: PropTypes.object,
  recaptchaPublicKey: PropTypes.string,
};

export default LoginPageContainer;
