// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--jv9te{display:flex;flex-direction:column;margin:0 var(--space-l)}.container--jv9te div.nds-tag--subdued{color:var(--theme-primary);font-weight:var(--font-weight-semibold);background:rgba(var(--theme-rgb-primary), var(--subdued-10-opacity))}.mobileWebExtraSpace--MIv49{display:flex;flex-direction:column;align-self:center;width:100%;max-width:568px}", "",{"version":3,"sources":["webpack://./components/Container/Container.module.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,qBAAA,CACA,uBAAA,CAEA,uCACE,0BAAA,CACA,uCAAA,CACA,oEAAA,CAIJ,4BACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":["@import \"../../../scss/mixins\";\n\n.container {\n  display: flex;\n  flex-direction: column;\n  margin: 0 var(--space-l);\n\n  div:global(.nds-tag--subdued) {\n    color: var(--theme-primary);\n    font-weight: var(--font-weight-semibold);\n    background: rgba(var(--theme-rgb-primary), var(--subdued-10-opacity));\n  }\n}\n\n.mobileWebExtraSpace {\n  display: flex;\n  flex-direction: column;\n  align-self: center;\n  width: 100%;\n  max-width: 568px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--jv9te",
	"mobileWebExtraSpace": "mobileWebExtraSpace--MIv49"
};
export default ___CSS_LOADER_EXPORT___;
