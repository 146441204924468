var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DueDiligenceFluentIds, getStringSettingFluentId, ProductFluentIds, SupportContactFluentIds, SupportFaqFluentIds, SupportLinksFluentIds, OaoDisclosuresFluentIds, } from "./SettingFluentIds";
var SETTINGS_LOCALE_SUFFIX = "-settings";
// Checks current bundles to determine if the setting
// should be translated or not
export var hasSettingTranslations = function (bundles) {
    var locales = __spreadArray([], __read(bundles), false).map(function (b) { return b.locales[0]; });
    var currentLocale = locales[0];
    var hasSettingsFtl = locales.some(function (l) {
        return l.includes(SETTINGS_LOCALE_SUFFIX);
    });
    return currentLocale !== "en" && hasSettingsFtl;
};
/**
 * Translates a string type setting
 * @usage
 * Takes `SETTING_NAME` as defined in python as `setting-name`
 * ```
 * translateStringSetting("institution-announcement", "My announcement!", l10n)
 * ```
 */
export var translateStringSetting = function (settingName, settingValue, l10n) {
    if (settingValue === void 0) { settingValue = ""; }
    return !hasSettingTranslations(Array.from(l10n.bundles))
        ? settingValue
        : l10n.getString(getStringSettingFluentId(settingName), null, settingValue);
};
/**
 * @usage
 * ```
 * // single product
 * const myLocalizedProduct = getLocalizedProduct(product, l10n);
 *
 * // multiple products
 * ```
 * products.map((p) => getLocalizedProduct(p, l10n))
 * ```
 */
export var translateProduct = function (product, l10n) {
    var localizedProduct = __assign({}, product);
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (shouldLocalize) {
        var ids_1 = new ProductFluentIds(product.id);
        localizedProduct.name = l10n.getString(ids_1.getName(), null, product.name);
        localizedProduct.description = l10n.getString(ids_1.getDescription(), null, product.description);
        if (localizedProduct.details) {
            localizedProduct.details = localizedProduct.details.map(function (detail, i) {
                return l10n.getString(ids_1.getDetail(i), null, detail);
            });
        }
        if (localizedProduct.disclosures) {
            localizedProduct.disclosures = localizedProduct.disclosures.map(function (disclosure, i) {
                var translatedDisclosure = {};
                if (disclosure.checkbox_text) {
                    (translatedDisclosure.checkbox_text = l10n.getString(ids_1.getDisclosureCheckboxText(i), null, disclosure.checkbox_text)),
                        null,
                        disclosure.checkbox_text;
                }
                if (disclosure.markdown) {
                    (translatedDisclosure.markdown = l10n.getString(ids_1.getDisclosureMarkdown(i), null, disclosure.markdown)),
                        null,
                        disclosure.markdown;
                }
                if (disclosure.links) {
                    translatedDisclosure.links = disclosure.links.map(function (_a, linkIndex) {
                        var text = _a.text, url = _a.url;
                        return ({
                            text: l10n.getString(ids_1.getDisclosureLinkText(i, linkIndex), null, text),
                            url: url,
                        });
                    });
                }
                return translatedDisclosure;
            });
        }
    }
    return localizedProduct;
};
export var translateDueDiligenceQuestions = function (questions, aoType, l10n) {
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (!shouldLocalize) {
        return questions;
    }
    var ids = new DueDiligenceFluentIds(aoType);
    var localizedQuestions = questions.map(function (question, index) {
        var localizedQuestion = __assign({}, question);
        localizedQuestion.question = l10n.getString(ids.getQuestion(index), null, question.question);
        var questionType = question.questionType;
        if (!questionType) {
            return localizedQuestion;
        }
        else if (questionType === "dropdown") {
            localizedQuestion.label = l10n.getString(ids.getQuestionLabel(index), null, question.label);
        }
        else if (questionType === "freeform") {
            localizedQuestion.label = l10n.getString(ids.getQuestionLabel(index), null, question.label);
            localizedQuestion.options = __spreadArray([], __read((localizedQuestion.options || [])), false).map(function (option, optionIndex) {
                return l10n.getString(ids.getDropdownQuestionOption(index, optionIndex), null, option);
            });
        }
        return localizedQuestion;
    });
    return localizedQuestions;
};
/**
 * @usage
 * ```
 * const translatedContacts = translateSupportContacts(contacts, l10n)
 * ```
 */
export var translateSupportContacts = function (contacts, l10n) {
    var localizedContacts = __spreadArray([], __read(contacts), false);
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (shouldLocalize) {
        var ids_2 = new SupportContactFluentIds();
        localizedContacts = contacts.map(function (contact, i) {
            contact.title = l10n.getString(ids_2.getTitle(i), null, contact.title);
            if (contact.numbers) {
                contact.numbers.map(function (number, numberIdx) {
                    number.name = l10n.getString(ids_2.getNumberName(i, numberIdx), null, number.name);
                    return number;
                });
            }
            return contact;
        });
    }
    return localizedContacts;
};
export var translateFaqQuestions = function (categories, l10n) {
    var localizedCategories = __spreadArray([], __read(categories), false);
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (shouldLocalize) {
        var ids_3 = new SupportFaqFluentIds();
        localizedCategories.forEach(function (category, i) {
            var currentCategory = localizedCategories[i];
            currentCategory.category = l10n.getString(ids_3.getCategory(i), null, category.category);
            (currentCategory.questions || []).forEach(function (_, questionIndex) {
                var currentQuestion = currentCategory.questions[questionIndex];
                currentQuestion.question = l10n.getString(ids_3.getQuestion(i, questionIndex), null, currentQuestion.question);
                currentQuestion.answer = l10n.getString(ids_3.getAnswer(i, questionIndex), null, currentQuestion.answer);
            });
        });
    }
    return localizedCategories;
};
export var translateSupportLinks = function (links, l10n) {
    var localizedLinks = __spreadArray([], __read(links), false);
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (shouldLocalize) {
        var ids_4 = new SupportLinksFluentIds();
        localizedLinks = links.map(function (_a, i) {
            var text = _a.text;
            return (__assign(__assign({}, links[i]), { text: l10n.getString(ids_4.getText(i), null, text) }));
        });
    }
    return localizedLinks;
};
/**
 * @usage
 * ```
 * const translatedDisclosures = translateOaoDisclosures(disclosures, l10n)
 * ```
 */
export var translateOaoDisclosures = function (disclosures, l10n) {
    var localizedDisclosures = __spreadArray([], __read(disclosures), false);
    var shouldLocalize = hasSettingTranslations(Array.from(l10n.bundles));
    if (shouldLocalize) {
        var ids_5 = new OaoDisclosuresFluentIds();
        localizedDisclosures = disclosures.map(function (disclosure, i) {
            if (disclosure.markdown) {
                disclosure.markdown = l10n.getString(ids_5.getMarkdown(i), null, disclosure.markdown);
            }
            if (disclosure.checkbox_text) {
                disclosure.checkbox_text = l10n.getString(ids_5.getCheckboxText(i), null, disclosure.checkbox_text);
            }
            if (disclosure.links) {
                disclosure.links.map(function (link, linkIndex) {
                    link.text = l10n.getString(ids_5.getLinkText(i, linkIndex), null, link.text);
                    return link;
                });
            }
            return disclosure;
        });
    }
    return localizedDisclosures;
};
