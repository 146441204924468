var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import { Button, ContextForm, TextInput, useFormData, useLoadingContext, } from "cerulean";
import HeaderWithSteps from "../HeaderWithSteps";
import { fetchSudoCode } from "./utils";
import Container from "../Container";
import BackButton from "./BackButton";
import ActionBar from "./ActionBar";
import { useSudoContext, NUMBER_OF_SUDO_STEPS, SudoRoutes, } from "./SudoContext";
var SubmitOTP = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var _a = location.state || {}, selectedDeviceId = _a.selectedDeviceId, initialHelpMessage = _a.helpMessage;
    var _b = __read(useState(initialHelpMessage === null || initialHelpMessage === void 0 ? void 0 : initialHelpMessage.message), 2), helpMessage = _b[0], setHelpMessage = _b[1];
    var _c = useSudoContext(), destinationRoute = _c.destinationRoute, totalSteps = _c.totalSteps;
    var setIsLoading = useLoadingContext().setIsLoading;
    var _d = useFormData(), formData = _d.formData, onChange = _d.onChange;
    var sendCode = function (callback) {
        ApiHttp.fetch("sudo", { method: "POST" }, { one_time_password: formData.one_time_password })
            .then(function () {
            navigate(destinationRoute || "/");
        })
            .catch(function () {
            callback({
                one_time_password: "Incorrect verification code entered, please try again.",
            });
        });
    };
    var requestCodeAgain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, fetchSudoCode(selectedDeviceId)];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.message) {
                        setHelpMessage(response.message);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var subheader = (
    /*
    For most flows that use HeaderWithSteps, spacing b/t header and subheader is 8px on l/m, 4px on s/xs.
    For sudo, it's always 4px, so we need to subtract 4px here
    Also, we only want 20px of margin b/t the resend code button and the content (vs. the typical 40px)
    */
    _jsxs("div", { style: { marginTop: "-4px", marginBottom: "-20px" }, children: [helpMessage || "A verification code was just sent to your device.", _jsx("div", { className: "margin--top--xs", children: _jsx(Button, { kind: "plain", onClick: requestCodeAgain, label: "Resend code", size: "s" }) })] }));
    return (_jsxs(Container, { children: [_jsx(HeaderWithSteps, { step: totalSteps ? totalSteps - NUMBER_OF_SUDO_STEPS : null, totalSteps: totalSteps, headerText: "Enter verification code", subheaderText: subheader }), _jsxs(ContextForm, { data: formData, onChange: onChange, formID: "submit-otp", children: [_jsx(ContextForm.Field, { required: true, children: _jsx(TextInput, { field: "one_time_password", label: "Verification code" }) }), _jsxs(ActionBar, { children: [_jsx(BackButton, { to: SudoRoutes.RequestOTP }), _jsx(ContextForm.Action, { onSubmit: sendCode, dangerouslyDisableShowLoading: true, children: _jsx(Button, { type: "submit", label: "Continue" }) })] })] })] }));
};
export default SubmitOTP;
