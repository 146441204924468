import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tabs, SeparatorList } from "@narmi/design_system";
import Account from "byzantine/src/Account";
import Transaction from "byzantine/src/Transaction";
import ApiPaginatedList from "byzantine/src/ApiPaginatedList";
import { NotificationContext } from "cerulean";
import SectionCard from "../SectionCard";
import TransactionTable, { EmptyTransactionState } from "../TransactionTable";
import PageNavigation from "../page_navigation/PageNavigation";
import TransactionSearchAndFilter, {
  TransactionFilterTagSection,
  convertFiltersToQuery,
} from "./TransactionSearchAndFilter";
import TransactionsDownloader from "./TransactionsDownloader";
import usePendingTransactions from "../hooks/usePendingTransactions";

const HandleParseTransactions = ({
  addSearchableCategories,
  addSearchableTags,
  transactions,
}) => {
  /* sole purpose of this component is to add tags and categories from newly-retrieved transactions
   * inside an effect hook since PaginatedTransactionTable cannot access the retrieved transactions and instead
   * passes that info to its children.
   */
  useEffect(() => {
    // only care about tags/categories from posted transactions since pending transactions cannot have tags/categories
    const postedTransactions = transactions.filter((t) => t?.posted_date);

    const tags = postedTransactions
      .map((transaction) => transaction.tags)
      .flat() // Transaction.tags is an array of strings so needs to be flattened first
      .filter((tag) => tag);

    const categories = postedTransactions
      .map((transaction) => transaction.category)
      .filter((category) => category);

    addSearchableTags(tags);
    addSearchableCategories(categories);
  }, [JSON.stringify(transactions)]);

  return null;
};
HandleParseTransactions.propTypes = {
  addSearchableCategories: PropTypes.func.isRequired,
  addSearchableTags: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction)).isRequired,
};

const PaginatedTransactionTable = ({
  account,
  searchFilters,
  addSearchableCategories,
  addSearchableTags,
  pendingTransactions,
  replyTime,
}) => {
  const { sendNotification } = useContext(NotificationContext);
  const searchQuery = convertFiltersToQuery(searchFilters);

  const errorCallback = () => {
    sendNotification({
      type: "negative",
      text: "There was an error retrieving your transactions. Please try again.",
    });
  };

  return (
    <ApiPaginatedList
      endpoint={`accounts/${account.id}/transactions`}
      queryParams={{ q: `${searchQuery} is:settled` }}
      keyname="transactions"
      pageSize={15}
      errorCallback={errorCallback}
    >
      {({ loading: areTransactionsLoading, items, pagination }) => {
        if (areTransactionsLoading) {
          return <SectionCard isLoading={areTransactionsLoading} />;
        }
        if (items.length === 0) {
          return <div className="margin--top--s">No transactions found.</div>;
        }
        // only show pending transactions for the first page of a retrieval without search query
        const filter =
          pagination.page !== 1 || searchQuery !== ""
            ? (a) => a.posted_date
            : (a) => a;
        const transactions = Transaction.sortByPostedDate(
          [
            ...pendingTransactions,
            ...items.map((a) => Transaction.deserialize(a, account)),
          ].filter(filter)
        );

        return (
          <div>
            <HandleParseTransactions
              addSearchableCategories={addSearchableCategories}
              addSearchableTags={addSearchableTags}
              transactions={transactions}
            />
            <TransactionTable
              isSourceMultipleAccounts={false}
              transactions={transactions}
              replyTime={replyTime}
            />
            <PageNavigation
              pagination={pagination}
              marginClasses="margin--top--l"
            />
          </div>
        );
      }}
    </ApiPaginatedList>
  );
};
PaginatedTransactionTable.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  searchFilters: PropTypes.object.isRequired,
  addSearchableCategories: PropTypes.func.isRequired,
  addSearchableTags: PropTypes.func.isRequired,
  pendingTransactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction))
    .isRequired,
  replyTime: PropTypes.string,
};

const TransactionsCard = ({ account, recentTransactions, replyTime }) => {
  const [searchFilters, setSearchFilters] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const switchToAllTransactionsTab = () => setTabIndex(1);

  // using sets to ensure list of tags and categories are unique
  const [searchableTags, setSearchableTags] = useState(new Set());
  const addSearchableTags = (newTags) => {
    setSearchableTags((prev) => new Set([...prev, ...newTags]));
  };
  const [searchableCategories, setSearchableCategories] = useState(new Set());
  const addSearchableCategories = (newCategories) => {
    setSearchableCategories((prev) => new Set([...prev, ...newCategories]));
  };
  const [pendingTransactions, setPendingTransactions] = useState([]);
  usePendingTransactions({ accounts: [account], setPendingTransactions });

  const displayedRecentTransactions = pendingTransactions
    .concat(recentTransactions)
    .slice(0, 5);

  return (
    <SectionCard isLoading={false} paddingSize={null}>
      {recentTransactions.length > 0 ? (
        <>
          <div className="padding--x--l padding--top--l padding--bottom--s">
            <SectionCard.Title
              text="Transaction history"
              button={
                <SeparatorList
                  items={[
                    <TransactionSearchAndFilter
                      key={`Search & filter`}
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                      switchToAllTransactionsTab={switchToAllTransactionsTab}
                      searchableTags={[...searchableTags]}
                      searchableCategories={[...searchableCategories]}
                    />,
                    <TransactionsDownloader
                      key="Download"
                      accountUuid={account.id}
                    />,
                  ]}
                />
              }
            />
          </div>
          <Tabs selectedIndex={tabIndex} onTabChange={setTabIndex}>
            <Tabs.List xPadding="l">
              <Tabs.Tab label="Recent" tabId="recent" />
              <Tabs.Tab label="All" tabId="all" />
            </Tabs.List>
            <Tabs.Panel tabId="recent">
              <div className="padding--x--l padding--bottom--xs">
                <TransactionTable
                  isSourceMultipleAccounts={false}
                  transactions={displayedRecentTransactions}
                  replyTime={replyTime}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel tabId="all">
              <div className="padding--x--l padding--bottom--xs">
                <TransactionFilterTagSection
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                />
                <PaginatedTransactionTable
                  account={account}
                  searchFilters={searchFilters}
                  addSearchableTags={addSearchableTags}
                  addSearchableCategories={addSearchableCategories}
                  pendingTransactions={pendingTransactions}
                  replyTime={replyTime}
                />
              </div>
            </Tabs.Panel>
          </Tabs>
        </>
      ) : (
        <div className="padding--all--l">
          <SectionCard.Title text="Transaction history" />
          <EmptyTransactionState isSourceMultipleAccounts={false} />
        </div>
      )}
    </SectionCard>
  );
};
TransactionsCard.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  recentTransactions: PropTypes.arrayOf(PropTypes.instanceOf(Transaction))
    .isRequired,
  replyTime: PropTypes.string,
};

export default TransactionsCard;
