import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalization } from "@fluent/react";
import { Drawer, Alert, useBreakpoints } from "cerulean";
import CardImage from "../CardImage";
import LockCard from "./LockCard";
import ReplaceCard from "./ReplaceCard";
import styles from "./CardManagerDrawer.module.scss";
var CardManagerDrawer = function (_a) {
    var isOpen = _a.isOpen, card = _a.card, onUserDismiss = _a.onUserDismiss, updateCardStateById = _a.updateCardStateById;
    var l10n = useLocalization().l10n;
    var isLocked = (card === null || card === void 0 ? void 0 : card.state) === "locked";
    var _b = useBreakpoints(), s = _b.s, m = _b.m;
    if (!card) {
        return null;
    }
    return (_jsx(Drawer, { depth: "470px", isOpen: isOpen, onUserDismiss: onUserDismiss, showControls: false, paddingSize: !s || (s && !m) ? "none" : "xl", children: _jsxs("div", { className: styles.cardManagerDrawerContainer, children: [_jsx("div", { className: styles.cardImageContainer, children: _jsx(CardImage, { name: card.name, textColor: card.text_color, size: "large", isLocked: isLocked, image: card.image, lastFourDigits: card.last_four_digits, network: card.network }) }), card.state === "damaged" && (_jsx("div", { className: styles.bannerInfo, children: _jsx(Alert, { isActive: true, isDismissable: false, kind: "info", children: l10n.getString("card-controls-damaged-card-drawer-description") }) })), _jsx(LockCard, { state: card.state, isLocked: isLocked, cardId: card.id, updateCardStateById: updateCardStateById }), _jsx(ReplaceCard, { cardId: card.id, state: card.state, onUserDismiss: onUserDismiss })] }) }));
};
export default CardManagerDrawer;
