import { DateTime } from "luxon";
import {
  FREQUENCIES,
  generateFrequencyString,
  generateRecurrenceRuleString,
} from "./Recurrence";
import Filters from "./filters";
import ApiHttp from "./ApiHttp";
import Account from "./Account";
import { featureEnabled } from "./Feature";
import utils from "./utils";
import { rrulestr } from "rrule";

class TransferSchedule {
  constructor(props) {
    this.transfer_uuid = props.transfer_uuid;
    this.amount = props.amount;
    this.from_account_id = props.from_account_id;
    this.to_account_id = props.to_account_id;
    this.frequency = props.frequency;
    this.start_date = props.start_date;
    this.next_transfer_at = props.next_transfer_at || null;
    this.is_recurring = props.is_recurring || false;
    this.memo = props.memo;
    this.description = props.description;
    this.limits_as_float = props.limitsAsFloat || {};
    this.ach_use_available_balance_for_scheduled_push =
      props.achUseAvailableBalanceForScheduledPush || false;
    this.accounts = props.accounts || [];
    this.useBusinessVerbiage = props.useBusinessVerbiage || false; // need this for validation error copy
    this.itempotencyKey = props.itempotencyKey;
  }

  static isImmediateOneTimeTransfer(frequency, start_date) {
    return (
      frequency === FREQUENCIES.ONCE &&
      DateTime.fromFormat(start_date, "yyyy-MM-dd").hasSame(
        DateTime.now(),
        "day"
      )
    );
  }

  // For ACH Push immediate one-time transfer, first validate based on ACH push limit
  // Then validate against available balance if transfer is immediate one-time or if transfer is scheduled and achUseAvailableBalanceForScheduledPush is enabled
  // It is possible for biz users to also have sub-user ACH Push limits, so check that as well
  validateACHPushTransfer(sourceAccount, isImmediateOneTimeTransfer) {
    // if the limit isn't defined for this user, allow them to proceed
    if (
      !(
        "ach_push" in this.limits_as_float ||
        "organization_user_ach_push" in this.limits_as_float
      )
    ) {
      return null;
    }

    const achPushLimit = Math.min(
      ...[
        this.limits_as_float?.ach_push,
        this.limits_as_float?.organization_user_ach_push,
      ].filter((limit) => limit !== undefined)
    );
    // ensure the error message accurately reflects which limit is being applied
    if (isImmediateOneTimeTransfer && this.amount > achPushLimit) {
      if (
        this.useBusinessVerbiage &&
        this.limits_as_float?.ach_push === achPushLimit
      ) {
        return `This amount exceeds your company's remaining external transfer push limit of ${Filters.currency(
          achPushLimit,
          { hasDecimal: true }
        )}.`;
      }
      return `This amount exceeds your remaining external transfer push limit of ${Filters.currency(
        achPushLimit,
        { hasDecimal: true }
      )}.`;
    }

    if (
      isImmediateOneTimeTransfer ||
      this.ach_use_available_balance_for_scheduled_push
    ) {
      return this.validateTransferAmountDoesNotExceedAvailableBalance(
        sourceAccount
      );
    }
    return null;
  }

  // Only validate ACH Pull Transfer against ACH Pull if it is an immediate one-time transfer
  // We don't validate against available balance because the source account is external
  validateACHPullTransfer(isImmediateOneTimeTransfer) {
    // if the limit isn't defined for this user, allow them to proceed
    if (!("ach_pull" in this.limits_as_float)) {
      return null;
    }
    const achPullLimit = this.limits_as_float.ach_pull;
    if (isImmediateOneTimeTransfer && this.amount > achPullLimit) {
      if (this.useBusinessVerbiage) {
        return `This amount exceeds your company's remaining external transfer pull limit of ${Filters.currency(
          achPullLimit,
          { hasDecimal: true }
        )}.`;
      }
      return `This amount exceeds your remaining external transfer pull limit of ${Filters.currency(
        achPullLimit,
        { hasDecimal: true }
      )}.`;
    }
    return null;
  }

  // Only validate internal transfer against available balance if it is an immediate one-time transfer
  // No other limits are applied for internal transfers
  validateInternalTransfer(sourceAccount, isImmediateOneTimeTransfer) {
    if (!isImmediateOneTimeTransfer) {
      return null;
    }
    return this.validateTransferAmountDoesNotExceedAvailableBalance(
      sourceAccount
    );
  }

  validateTransferAmountDoesNotExceedAvailableBalance(account) {
    if (!account) {
      return "Please specify a source account.";
    }
    if (this.amount > account.transferableBalanceAsFloat()) {
      return "Transfer amount cannot exceed source account's available balance.";
    }
    return null;
  }

  sourceAccount() {
    return Account.getAccountFromId(this.from_account_id, this.accounts);
  }

  destinationAccount() {
    return Account.getAccountFromId(this.to_account_id, this.accounts);
  }

  // Returns null if transfer is valid, otherwise an error string rendered to the user
  validateTransferAmount() {
    const sourceAccount = this.sourceAccount();
    const destinationAccount = this.destinationAccount();
    if (!sourceAccount || !destinationAccount) {
      return "Please select valid source and destination accounts.";
    }
    if (!this.amount) {
      return "Please enter a valid transfer amount.";
    }
    if (!this.frequency) {
      return "Please select a transfer frequency.";
    }
    if (!this.start_date) {
      return "Please select a transfer date.";
    }
    const isImmediateOneTimeTransfer =
      TransferSchedule.isImmediateOneTimeTransfer(
        this.frequency,
        this.start_date
      );
    if (sourceAccount.isInternal() && destinationAccount.isInternal()) {
      return this.validateInternalTransfer(
        sourceAccount,
        isImmediateOneTimeTransfer
      );
    }
    if (sourceAccount.isExternal()) {
      return this.validateACHPullTransfer(isImmediateOneTimeTransfer);
    }
    if (destinationAccount.isExternal()) {
      return this.validateACHPushTransfer(
        sourceAccount,
        isImmediateOneTimeTransfer
      );
    }
    return null;
  }

  immediatePayload() {
    return {
      amount: utils.dollarsToPennies(this.amount.replace(/[^\d.]/g, "")),
      from_account_id: this.from_account_id,
      to_account_id: this.to_account_id,
      agreement: true,
      memo: this.memo,
      ...(this.transfer_uuid ? { transfer_uuid: this.transfer_uuid } : {}),
    };
  }

  scheduledPayload() {
    return Object.assign({}, this.immediatePayload(), {
      recurring_rule: generateRecurrenceRuleString(
        this.frequency,
        this.start_date
      ),
    });
  }

  submit() {
    const headers = this.itempotencyKey
      ? { "Idempotency-Key": this.itempotencyKey }
      : {};
    if (
      TransferSchedule.isImmediateOneTimeTransfer(
        this.frequency,
        this.start_date
      )
    ) {
      return ApiHttp.fetch(
        "transfers",
        { method: "POST", headers },
        this.immediatePayload()
      );
    }
    return ApiHttp.fetch(
      "transfers/scheduled",
      { method: "POST", headers },
      this.scheduledPayload()
    );
  }

  update() {
    const headers = this.itempotencyKey
      ? { "Idempotency-Key": this.itempotencyKey }
      : {};
    return ApiHttp.fetch(
      "transfers/scheduled",
      { method: "PUT", headers },
      this.scheduledPayload()
    );
  }

  getTransferDirectionInformation (account) {
    let direction;
    let otherAccount;
    
    if (account.id === this.from_account_id) {
      direction = "outgoing";
      otherAccount = this.destinationAccount();
    } else {
      direction = "incoming";
      otherAccount = this.sourceAccount();
    }

    return { direction, otherAccountName: otherAccount?.getName() }
  }

  getAmountText(direction) {
    switch (direction) {
      case "incoming":
        return Filters.currency(this.amount, {
          hasDecimal: true,
          hasSign: true,
        })

      case "outgoing":
        return Filters.currency(-this.amount, {
          hasDecimal: true,
          hasSign: true,
        })

      default:
        return ""
    }
  }

  getNextTransferDate () {
    let dateText = "";
    if (this.next_transfer_at) {
      dateText = Filters.longMonthDayYear(this.next_transfer_at);
    }
    return dateText
  }

  displayData(account) {
    const { direction, otherAccountName } = this.getTransferDirectionInformation(account)
    return ({
      direction,
      otherAccountName, 
      frequency: this.frequency,
      isRecurring: this.is_recurring,
      defaultDescription: this.description,
      amount: this.getAmountText(direction),
      nextTransferDate: this.getNextTransferDate(),
    })
  }

  displayForAccount(account) {
    let dateText = this.getNextTransferDate();

    if (this.is_recurring) {
      dateText = `Next transfer: ${dateText}`;
    }
    
    const common = {
      frequency: this.frequency,
      next: dateText,
    };

    const { otherAccountName, direction } = this.getTransferDirectionInformation(account)

    if (!otherAccountName) {
      // sometimes the client does not have access to otherAccount
      // in this case, just return the scheduled transfer description
      return Object.assign(common, {
        description: this.description,
        amount: this.getAmountText("outgoing"),
      });
    }

    const amount = this.getAmountText(direction);

    if (direction === "outgoing") {
      return Object.assign(common, {
        description: `Transfer to ${otherAccountName}`,
        amount,
      });
    } else if (direction === "incoming") {
      return Object.assign(common, {
        description: `Transfer from ${otherAccountName}`,
        amount,
      });
    } else {
      // Only display the transfer for the two accounts involved
      return null;
    }
  }

  isInternalTransfer() {
    return !!this.sourceAccount()?.isInternal() && !!this.destinationAccount()?.isInternal();
  }

  transferFeatureToCheck() {
    return this.isInternalTransfer() ? "internal_transfers" : "ach";
  }

  isEditable(userFeatures) {
    // in order to edit, user must have the ability to edit scheduled transfers, access to source and destination accounts,
    // and the ability to perform the type of transfer (internal or ach) that they're operating on
    return featureEnabled(userFeatures, { and: ["edit_scheduled_transfers", this.transferFeatureToCheck()] }) && !!this.sourceAccount() && !!this.destinationAccount();
  }

  isDeletable(userFeatures, user) {
    // a personal user or account holder can always delete a transfer that they have access to
    // a business subuser needs to have access and needs to have the relevant feature flag
    return user.isPersonalUserOrBusinessAdmin() || featureEnabled(userFeatures, { or: [this.transferFeatureToCheck()] });
  }

  static deserialize(payload, accounts) {
    const rrule = rrulestr(payload.recurring_rule);
    return new TransferSchedule({
      ...payload,
      amount: [null, undefined, ""].includes(payload.amount)
        ? null
        : payload.amount / 100,
      frequency: generateFrequencyString(rrule),
      start_date: rrule.options.dtstart,
      is_recurring: rrule.options.count !== 1,
      memo: payload.memo || "",
      accounts,
    });
  }
}

export default TransferSchedule;
