var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useState } from "react";
import { useNotificationContext, ContentCard, Toggle } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
import { useLocalization } from "@fluent/react";
import styles from "./LockCard.module.scss";
var LockCard = function (_a) {
    var cardId = _a.cardId, isLocked = _a.isLocked, updateCardStateById = _a.updateCardStateById;
    var _b = useNotificationContext(), sendNotification = _b.sendNotification, clearNotifications = _b.clearNotifications;
    var l10n = useLocalization().l10n;
    var _c = __read(useState(false), 2), isSaving = _c[0], setIsSaving = _c[1];
    var changeCardState = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    clearNotifications();
                    setIsSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiHttp.fetch("cards/".concat(cardId, "/").concat(isLocked ? "unlock" : "lock"), { method: "POST" }, {})];
                case 2:
                    response = _a.sent();
                    updateCardStateById(cardId, response.state);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    sendNotification({
                        type: "negative",
                        text: l10n.getString("error-unknown"),
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsSaving(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: styles.toggleCardContainer, children: _jsxs(ContentCard, { kind: "elevated", children: [_jsxs("label", { id: "lock-label", className: styles.toggleContainer, children: [_jsx("span", { children: l10n.getString(isLocked ? "card-feature-unfreeze" : "card-feature-freeze") }), _jsx(Toggle, { labelledBy: "lock-label", isActive: isLocked, isLoading: isSaving, onChange: function () {
                                changeCardState();
                            } })] }), _jsx("p", { className: "fontColor--secondary fontSize--s ".concat(styles.toggleDescription), children: l10n.getString(isLocked
                        ? "card-feature-unfreeze-description"
                        : "card-feature-freeze-description") })] }) }));
};
export default LockCard;
