import React from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";
import ContextForm from "../ContextForm";

// basic email validation to confirm that input has a @ and there is at least one char on either side of @
// returning falsey counterintuitively means that no errors are found
export function validateEmail(value, disabled) {
  if (disabled) return null;
  const invalidEmailError = "Please enter a valid email address";
  if (!value) return invalidEmailError;
  // ensure value has at least one '@'
  if (value.indexOf("@") === -1) {
    return invalidEmailError;
  }

  if (/\s/.test(value)) {
    return invalidEmailError;
  }

  const subStrings = value.split("@");
  // ensure string before '@' is truthy
  if (!subStrings[0]) {
    return invalidEmailError;
  }
  // ensure that string immediately following last '@' contains a '.'
  const last = subStrings.pop();
  if (last.indexOf(".") === -1) {
    return invalidEmailError;
  }
  // ensure that string/char immediately following '.' is not empty
  const remaining = last.split(".");
  if (!remaining[1]) {
    return invalidEmailError;
  }

  return null;
}

const EmailFormField = ({
  label,
  required = false,
  disabled,
  fieldStyle,
  className,
}) => {
  return (
    <ContextForm.Field
      required={required}
      validate={(e) => validateEmail(e, disabled)}
      style={fieldStyle}
      className={className}
    >
      <TextInput
        name="email"
        field="email"
        label={label || "Email"}
        type="email"
        data-test-element="email-input"
        disabled={disabled}
      />
    </ContextForm.Field>
  );
};
EmailFormField.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  fieldStyle: PropTypes.object,
  className: PropTypes.string,
};
export default EmailFormField;
