import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { ContentCard } from "@narmi/design_system";

export default function OptionCard({
  icon,
  title,
  superscript,
  description,
  iconSize,
  route,
  customClassName = "margin--all--xs nds-span-12 transfer-option-card",
  shouldOpenInNewTab = false,
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!shouldOpenInNewTab) {
      navigate(route); // if route is undefined, the page won't change
    } else {
      const currentPathName = window.location.pathname.replace(/\/$/, "");
      window.open(`${currentPathName}${route}`, "_blank", "noreferrer");
    }
  };

  return (
    <div className={customClassName}>
      <ContentCard kind="interactive" onClick={handleClick} paddingSize="none">
        <div className="transfer-card-content">
          <h4
            className="fontFamily--body"
            style={{ color: "var(--theme-primary)" }}
          >
            {title}
            <sup>{superscript}</sup>
            {icon && (
              <i
                className={`narmi-icon-${icon} padding--left--xs`}
                style={{
                  fontSize: iconSize,
                  transform: "translateY(10%)",
                  display: "inline-block",
                }}
              />
            )}
          </h4>
          <p className="fontColor--primary margin--none padding--top--xs">
            {description}
          </p>
        </div>
      </ContentCard>
    </div>
  );
}
OptionCard.propTypes = {
  icon: PropTypes.node,
  iconSize: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  superscript: PropTypes.string,
  description: PropTypes.string,
  /* React router route to follow when user clicks on the card */
  route: PropTypes.string,
  customClassName: PropTypes.string,
  shouldOpenInNewTab: PropTypes.bool,
};
