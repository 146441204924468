import OrganizationUser from "byzantine/src/OrganizationUser";
import OrganizationUserList, {
  DISPLAY_FILTERS,
  SORT,
} from "byzantine/src/OrganizationUserList";
import Filters from "byzantine/src/filters";
import { Button, DropdownListItem, Popover, TextInput } from "cerulean";
import PropTypes from "prop-types";
import React from "react";
import { useLocalization, Localized } from "@fluent/react";
import { Search } from "react-feather";
import { Popup, Table } from "semantic-ui-react";

import { LoadingShim } from "@narmi/design_system";

import BaseBanner from "../BaseBanner";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import FilterCheckbox from "../functional/FilterCheckbox";

// We have some hard coded English copy in byzantine that we must
// map to fluent ids for the OLB product FTL
const BYZANTINE_COPY_TO_FLUENT_ID = {
  Active: "org-user-status-active",
  Pending: "org-user-status-pending",
  Inactive: "org-user-status-inactive",
  "Account Holder": "org-user-role-account-holder",
  Admin: "org-user-role-admin",
  Viewer: "org-user-role-viewer",
  Collaborator: "org-user-role-collaborator",
  Deactivated: "org-user-status-deactivated",
};

export function Header({ search, setSearch, filters, toggleFilter }) {
  const { l10n } = useLocalization();
  const [editingSearchInResponsiveMode, setEditingSearchInResponsiveMode] =
    React.useState(false);

  const TeamBanner = (
    <BaseBanner
      bannerName={l10n.getString("banner-manage-team")}
      buttonFunction={() => window.location.assign("/manage_users/new_user")}
      buttonName={
        <>
          <span className="hide-at-m">+ </span>
          <span>{l10n.getString("button-add-new-user")}</span>
        </>
      }
      features={{ business_banking: true }}
      orFeatureFlagChecks={["business_banking"]}
    />
  );

  const SearchInput = (
    <TextInput
      startIcon={"search"}
      onChange={(event) => setSearch(event.target.value)}
      placeholder={l10n.getString("label-search-members")}
      value={search}
    />
  );

  const clearFilters = () => toggleFilter();

  const DropdownFilterComponent = () => {
    /* refactoring this because we need the parent component to know
    the open/close state of the child Dropdown to set the border color.

    this should be called using function syntax not component syntax,
    otherwise it will re-mount everytime a checkbox is selected
    */
    const PopoverContent = () => (
      <div className="padding--all--m">
        <div className="inputBox filter" style={{ width: "188px" }}>
          <div className="filterSectionTitleContainer">
            <span className="filterTitle">
              {l10n.getString("filter-by-role")}
            </span>
            <Button
              kind="negative"
              label={l10n.getString("button-clear")}
              onKeyUp={(ev) => {
                if (ev.key === "Enter") {
                  clearFilters();
                }
              }}
              onClick={clearFilters}
              size="s"
            />
          </div>
          <div>
            {DISPLAY_FILTERS.ROLE.map(({ text, filter }) => (
              <FilterCheckbox
                key={text}
                label={l10n.getString(BYZANTINE_COPY_TO_FLUENT_ID[text])}
                filters={filters}
                toggleFilter={toggleFilter}
                filter={filter}
              />
            ))}
          </div>
          <div className="filterSectionTitleContainer">
            <span className="filterTitle">
              {l10n.getString("filter-by-status")}
            </span>
          </div>
          <div>
            {DISPLAY_FILTERS.STATUS.map(({ text, filter }) => (
                <FilterCheckbox
                  key={text}
                  label={l10n.getString(BYZANTINE_COPY_TO_FLUENT_ID[text])}
                  filters={filters}
                  toggleFilter={toggleFilter}
                  filter={filter}
                />
              )
            )}
          </div>
        </div>
      </div>
    );

    return (
      <div className="filterSearchPopover">
        <Popover content={<PopoverContent />} wrapperDisplay="inline">
          <div className="nds-input" style={{ cursor: "pointer" }}>
            <div className="nds-input-box">
              <div className="nds-input-column">
                <div className="filterTextAndIcon">
                  <span>
                    {l10n.getString("org-active-filters-text", {
                      numFilters: filters.length,
                    })}
                  </span>
                  <div className="nds-input-icon narmi-icon-chevron-down"></div>
                </div>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  };

  if (editingSearchInResponsiveMode) {
    return (
      <div>
        {TeamBanner}
        <div className="searchAndFilterContainer">
          <div className="inputWithIcon search inputBox nds-input-style-override">
            {SearchInput}
          </div>
          <span className="cancelEditSearch">
            <Button
              kind="negative"
              label="Cancel"
              onClick={() => setEditingSearchInResponsiveMode(false)}
            />
          </span>
        </div>
        <div className="mobile-hidden">{DropdownFilterComponent()}</div>
      </div>
    );
  }

  return (
    <div>
      {TeamBanner}
      <div className="searchAndFilterContainer">
        <div
          role="button"
          tabIndex="0"
          className="mobile-only inputBox editSearch"
          data-testid="input-box"
          onKeyUp={({ key }) => {
            if (key === "Enter") {
              setEditingSearchInResponsiveMode(true);
            }
          }}
          onClick={() => setEditingSearchInResponsiveMode(true)}
        >
          <Search style={{ marginLeft: "-4px" }} />
        </div>
        <div className="mobile-hidden search inputBox nds-input-style-override">
          {SearchInput}
        </div>
        {DropdownFilterComponent()}
        <div className="mobile-only" style={{ flex: "1" }} />
      </div>
    </div>
  );
}

Header.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  filters: PropTypes.array,
  toggleFilter: PropTypes.func,
};

export const MemberNameAndErrorPopup = (props) => {
  // popup requires another displayed element to "tether" to
  // so hacking this by adding member name to display into this component
  const contextRef = React.useRef();
  const memberName = `${props.firstName} ${props.lastName}`;

  return (
    <div>
      <Popup
        trigger={<div></div>}
        style={{
          borderRadius: "8px",
          textAlign: "center",
          padding: "12px 16px",
          whiteSpace: "nowrap",
          left: "-13px",
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
          border: 0,
        }}
        position="top center"
        context={contextRef}
        open={props.isVisible}
        content={props.content}
        data-testid="memberNameAndErrorPopup"
      />
      <div ref={contextRef}>{memberName}</div>
    </div>
  );
};

MemberNameAndErrorPopup.propTypes = {
  content: PropTypes.string,
  isVisible: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

const OrganizationUserRow = ({ member }) => {
  const [isErrorShown, setIsErrorShown] = React.useState(false);
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return <LoadingShim loading={true} />;
  }
  const reason = member.reasonIfNotEditable(
    currentUser.org_role,
    currentUser.org_uuid
  );

  const editHover = () => {
    // error reason only pops up on click
    if (reason) setIsErrorShown(true);
  };

  const editUnhover = () => {
    // error reason disappears when the mouse leaves the member's row
    setIsErrorShown(false);
  };

  const goToEditUser = () => {
    if (reason) {
      editHover();
      return;
    }
    window.location = `/manage_users/edit_user/${member.uuid}`;
  };

  return (
    <Table.Row
      onMouseLeave={() => editUnhover()}
      onClick={() => goToEditUser()}
      style={{
        cursor: reason ? "auto" : "pointer",
      }}
    >
      <Table.Cell>
        <MemberNameAndErrorPopup
          isVisible={isErrorShown}
          content={reason}
          firstName={member.user.first_name}
          lastName={member.user.last_name}
        />
      </Table.Cell>
      <Table.Cell>{member.user.email}</Table.Cell>
      <Table.Cell>{member.role.getAccessLevelUnlessIsOwner()}</Table.Cell>
      <Table.Cell>
        {member.status === OrganizationUser.STATUS.INACTIVE
          ? "Deactivated"
          : member.status}
      </Table.Cell>
      <Table.Cell>
        {member.user.last_login
          ? Filters.americanDate(member.user.last_login)
          : "N/A"}
      </Table.Cell>
    </Table.Row>
  );
};
OrganizationUserRow.propTypes = {
  member: PropTypes.object,
};

const OrganizationUserMobileRow = ({ member }) => {
  /* the responsive version of `OrganizationUserRow`
     note that it currently does not display the reason a row is uneditable
   */
  const { currentUser } = useCurrentUser();

  if (!currentUser) {
    return <LoadingShim loading={true} />;
  }

  const reason = member.reasonIfNotEditable(
    currentUser?.org_role,
    currentUser?.org_uuid
  );

  const goToEditUser = () => {
    if (!reason) {
      window.location = `/manage_users/edit_user/${member.uuid}`;
    }
  };

  return (
    <div
      className="mobileListContents"
      role="button"
      tabIndex="0"
      onKeyUp={({ key }) => {
        if (key === "Enter") {
          goToEditUser();
        }
      }}
      onClick={() => {
        goToEditUser();
      }}
    >
      <div className="titleLine">
        <div className="name">
          {member.user.first_name} {member.user.last_name}
        </div>
        <span className="tag">{member.role.getAccessLevel()}</span>
      </div>
      <div>{member.user.email}</div>
      <div>
        {member.status} | Last login:{" "}
        {member.user.last_login
          ? Filters.americanDate(member.user.last_login)
          : ""}
      </div>
    </div>
  );
};

OrganizationUserMobileRow.propTypes = {
  member: PropTypes.object,
};

export const Contents = (props) => (
  <div>
    <div className="ui mobile-hidden">
      <Table className="OrganizationUsersTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Popover
                content={
                  <div className="access-manager-sorting">
                    <DropdownListItem
                      boldSelected={false}
                      anyItemSelected={[SORT.NAME_ASC, SORT.NAME_DESC].includes(
                        props.sortOrder
                      )}
                      isSelected={props.sortOrder === SORT.NAME_ASC}
                      onClick={() => props.setSortOrder(() => SORT.NAME_ASC)}
                    >
                      <Localized id="message-sort-alphabetical-asc">
                        Sort A to Z
                      </Localized>
                    </DropdownListItem>
                    <DropdownListItem
                      boldSelected={false}
                      anyItemSelected={[SORT.NAME_ASC, SORT.NAME_DESC].includes(
                        props.sortOrder
                      )}
                      isSelected={props.sortOrder === SORT.NAME_DESC}
                      onClick={() => props.setSortOrder(() => SORT.NAME_DESC)}
                    >
                      <Localized id="message-sort-alphabetical-desc">
                        Sort Z to A
                      </Localized>
                    </DropdownListItem>
                  </div>
                }
              >
                <div className="flex">
                  NAME
                  <span className="nds-input">
                    <span className="nds-input-icon narmi-icon-chevron-down" />
                  </span>
                </div>
              </Popover>
            </Table.HeaderCell>
            <Table.HeaderCell>EMAIL</Table.HeaderCell>
            <Table.HeaderCell>ROLE</Table.HeaderCell>
            <Table.HeaderCell>STATUS</Table.HeaderCell>
            <Table.HeaderCell>
              <Popover
                content={
                  <div className="access-manager-sorting">
                    <DropdownListItem
                      boldSelected={false}
                      anyItemSelected={[
                        SORT.LASTLOGIN_DESC,
                        SORT.LASTLOGIN_ASC,
                      ].includes(props.sortOrder)}
                      isSelected={props.sortOrder === SORT.LASTLOGIN_DESC}
                      onClick={() =>
                        props.setSortOrder(() => SORT.LASTLOGIN_DESC)
                      }
                    >
                      <Localized id="message-sort-chronological-desc">
                        Sort most recent to least
                      </Localized>
                    </DropdownListItem>
                    <DropdownListItem
                      boldSelected={false}
                      anyItemSelected={[
                        SORT.LASTLOGIN_DESC,
                        SORT.LASTLOGIN_ASC,
                      ].includes(props.sortOrder)}
                      isSelected={props.sortOrder === SORT.LASTLOGIN_ASC}
                      onClick={() =>
                        props.setSortOrder(() => SORT.LASTLOGIN_ASC)
                      }
                    >
                      <Localized id="message-sort-chronological-asc">
                        Sort least recent to most
                      </Localized>
                    </DropdownListItem>
                  </div>
                }
              >
                <div className="flex">
                  LAST LOGIN
                  <span className="nds-input">
                    <span className="nds-input-icon narmi-icon-chevron-down" />
                  </span>
                </div>
              </Popover>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.displayOrganizationUsers.map((member) => (
            <OrganizationUserRow key={member.uuid} member={member} />
          ))}
        </Table.Body>
      </Table>
    </div>
    <div className="mobile-only">
      {props.displayOrganizationUsers.map((member) => (
        <OrganizationUserMobileRow key={member.uuid} member={member} />
      ))}
    </div>
  </div>
);

Contents.propTypes = {
  displayOrganizationUsers: PropTypes.array,
  sortOrder: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export const Container = (props) => {
  const { l10n } = useLocalization();

  const NoResults = () => {
    const noResultsStyle = {
      backgroundColor: "rgb(var(--background-grey-color))",
      color: "rgb(var(--title-color))",
      padding: "20px 0px 0px 4px",
    };
    const smallerTextStyle = { fontSize: "16px", lineHeight: "20px" };

    return (
      <div style={noResultsStyle}>
        <p style={smallerTextStyle}>{l10n.getString("no-results-search")}</p>
      </div>
    );
  };

  const contents =
    props.displayOrganizationUsers.length === 0 ? (
      <NoResults />
    ) : (
      <Contents
        organizationUsers={props.organizationUsers}
        displayOrganizationUsers={props.displayOrganizationUsers}
        sortOrder={props.sortOrder}
        setSortOrder={props.setSortOrder}
      />
    );
  return (
    <div className="OrganizationUserList">
      <Header
        search={props.search}
        setSearch={props.setSearch}
        filters={props.filters}
        toggleFilter={props.toggleFilter}
        sortOrder={props.sortOrder}
        setSortOrder={props.setSortOrder}
      />
      {contents}
    </div>
  );
};

Container.propTypes = {
  organizationUsers: PropTypes.array,
  displayOrganizationUsers: PropTypes.array,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  filters: PropTypes.array,
  toggleFilter: PropTypes.func,
  sortOrder: PropTypes.func,
  setSortOrder: PropTypes.func,
};

export default function OrganizationUsers({ organizationUsers }) {
  return (
    <InstitutionSettingsContextProvider>
      <div style={{ minHeight: "800px" }}>
        <OrganizationUserList
          organizationUsers={organizationUsers}
          renderContainer={Container}
        />
      </div>
    </InstitutionSettingsContextProvider>
  );
}

OrganizationUsers.propTypes = {
  organizationUsers: PropTypes.array.isRequired,
};
