var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// TODO grab this from "react-native-quick-crypto" for mobile and "crypto" for web
import createHmac from "create-hmac";
import { Buffer } from "buffer";
import queryString from "query-string";
import Filters from "../filters";
import Utils from "../utils";
import customFetch, { REQUESTS_WITHOUT_BODY } from "./customFetch";
import { isApplicationResponse, isApplicationsResponse, isRawResponse, } from "./types";
// use v1 of the api
export var API_VERSION = "/v1/";
var OtsTokenError = /** @class */ (function (_super) {
    __extends(OtsTokenError, _super);
    function OtsTokenError(message) {
        var _this = _super.call(this, message) || this;
        _this.message = message;
        _this.name = "OtsTokenError";
        return _this;
    }
    return OtsTokenError;
}(Error));
export { OtsTokenError };
var DEFAULT_HEADERS = {
    Pragma: "no-cache",
    "Cache-Control": "no-store, no-cache",
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "en",
};
var DEFAULT_MAX_ATTEMPTS = 3;
var EPSILON_SECONDS = 60;
var buildAuthHeaders = function (token, secret) {
    if (!token) {
        return {};
    }
    var authHeaders = {
        Authorization: "Bearer ".concat(token),
    };
    if (secret) {
        var created = String(Math.floor(Date.now() / 1000));
        var signature = createHmac("sha256", secret)
            .update("created: ".concat(created), "utf8")
            .digest("base64");
        authHeaders.Signature = "keyId=\"".concat(token, "\",algorithm=\"hmac-sha256\",signature=\"").concat(signature, "\",headers=\"created\"");
        authHeaders.created = created;
    }
    return authHeaders;
};
var getDefaultApiUrl = function () {
    var REACT_APP_PUBLIC_API_URL;
    var NEXT_PUBLIC_PUBLIC_API_URL;
    try {
        REACT_APP_PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
        NEXT_PUBLIC_PUBLIC_API_URL = process.env.NEXT_PUBLIC_PUBLIC_API_URL;
    }
    catch (_a) {
        // if process is not defined, we just want to prefix with API_VERSION
        return "";
    }
    var defaultApiUrl = "";
    if (REACT_APP_PUBLIC_API_URL) {
        defaultApiUrl = REACT_APP_PUBLIC_API_URL;
    }
    else if (NEXT_PUBLIC_PUBLIC_API_URL) {
        defaultApiUrl = NEXT_PUBLIC_PUBLIC_API_URL;
    }
    // we had accidentally set REACT_APP_PUBLIC_API_URL to include '/v1/' in
    // the past but then switched to letting the client manage which version
    // of the api it supports (also eat trailing '/' if present)
    return defaultApiUrl.replace(/(\/v1)?\/?$/, "");
};
var ApiHttpClass = /** @class */ (function () {
    function ApiHttpClass(httpClient) {
        var _this = this;
        this.parseServerErrors = function (errors) {
            if (!errors) {
                return {};
            }
            return Object.keys(errors).reduce(function (acc, key) {
                var error = errors[key];
                if (typeof error === "string") {
                    acc[key] = error;
                }
                else if (Array.isArray(error)) {
                    acc[key] = error.join(" ");
                }
                else if (typeof error === "object") {
                    acc[key] = _this.parseServerErrors(error);
                }
                return acc;
            }, {});
        };
        this.httpClient = httpClient;
    }
    ApiHttpClass.isJWTExpired = function (token) {
        // not using atob here as that's browser only
        var payloadBase64 = token === null || token === void 0 ? void 0 : token.split(".")[1];
        if (!payloadBase64) {
            return false;
        }
        var decodedJson = Buffer.from(payloadBase64, "base64").toString();
        var decoded = JSON.parse(decodedJson);
        var exp = decoded.exp;
        if (!exp) {
            return false;
        }
        var expired = Date.now() >= (exp - EPSILON_SECONDS) * 1000;
        return expired;
    };
    ApiHttpClass.prototype.getCsrfToken = function (_a) {
        var _this = this;
        var csrfToken = _a.csrfToken, headers = _a.headers, endpoint = _a.endpoint, payload = _a.payload, method = _a.method, apiUrl = _a.apiUrl;
        var getCsrf = new Promise(function (resolve) {
            return resolve();
        });
        if (ApiHttpClass.isJWTExpired(ApiHttpClass.csrfToken)) {
            ApiHttpClass.csrfToken = null;
        }
        if (method.toUpperCase() === "GET") {
            // if doing a get, build our querystring if not already specified
            if (!/\?/.test(endpoint)) {
                endpoint += "?".concat(queryString.stringify(payload)); // eslint-disable-line no-param-reassign
            }
        }
        else if (ApiHttpClass.csrfToken) {
            getCsrf = Promise.resolve(ApiHttpClass.csrfToken);
        }
        else if (csrfToken) {
            getCsrf = Promise.resolve(csrfToken);
        }
        else {
            getCsrf = (function () { return __awaiter(_this, void 0, void 0, function () {
                var response, token, err_1;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.httpClient.request({
                                    url: "".concat(apiUrl).concat(API_VERSION, "csrf"),
                                    method: "GET",
                                    headers: headers,
                                    withCredentials: true,
                                })];
                        case 1:
                            response = _b.sent();
                            token = (_a = response === null || response === void 0 ? void 0 : response.headers) === null || _a === void 0 ? void 0 : _a["x-csrftoken"];
                            if (token) {
                                ApiHttpClass.csrfToken = token;
                                return [2 /*return*/, token];
                            }
                            else {
                                ApiHttpClass.csrfToken = null;
                                throw "No csrf token from server";
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _b.sent();
                            throw err_1;
                        case 3: return [2 /*return*/];
                    }
                });
            }); })();
        }
        return getCsrf;
    };
    ApiHttpClass.prototype.fetch = function (endpoint, options, payload, attempts) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
        if (options === void 0) { options = {}; }
        if (payload === void 0) { payload = {}; }
        if (attempts === void 0) { attempts = DEFAULT_MAX_ATTEMPTS; }
        return __awaiter(this, void 0, Promise, function () {
            var method, apiUrl, params, endpointIsUrl, headers, url, csrfToken, data, response, error_1, message;
            return __generator(this, function (_2) {
                switch (_2.label) {
                    case 0:
                        method = options.method || "GET";
                        apiUrl = options.apiUrl || getDefaultApiUrl();
                        params = options.params || {};
                        endpointIsUrl = Boolean(options.endpointIsUrl);
                        headers = Object.assign({}, DEFAULT_HEADERS, buildAuthHeaders(options.token, options.secret), options.headers || {});
                        url = endpointIsUrl ? endpoint : "".concat(apiUrl).concat(API_VERSION).concat(endpoint);
                        _2.label = 1;
                    case 1:
                        _2.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.getCsrfToken({
                                csrfToken: options.csrfToken,
                                headers: headers,
                                endpoint: endpoint,
                                payload: payload,
                                method: method,
                                apiUrl: apiUrl,
                            })];
                    case 2:
                        csrfToken = _2.sent();
                        if (csrfToken) {
                            headers["x-csrftoken"] = csrfToken;
                        }
                        data = ApiHttpClass.serialize(payload, options.shouldSkipSerializeAmount);
                        return [4 /*yield*/, this.httpClient.request({
                                url: url,
                                data: data,
                                method: method,
                                headers: headers,
                                params: params,
                                withCredentials: true,
                                onUploadProgress: options.onUploadProgress,
                            })];
                    case 3:
                        response = _2.sent();
                        if (isApplicationResponse(response)) {
                            ApiHttpClass.deserialize(response.data.application);
                        }
                        if (isApplicationsResponse(response)) {
                            response.data.applications.forEach(function (application) {
                                ApiHttpClass.deserialize(application);
                            });
                        }
                        // for responses with attachments, we need the file name to save the attachment as
                        if (isRawResponse(response, !!options.rawResponse)) {
                            return [2 /*return*/, response];
                        }
                        return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data];
                    case 4:
                        error_1 = _2.sent();
                        if (options.rawResponse) {
                            throw error_1;
                        }
                        if (((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                            throw "A server error occurred. Please try again later or contact Support.";
                        }
                        if (((_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                            throw "A permissions error occurred. Please contact Support.";
                        }
                        if (!((_c = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _c === void 0 ? void 0 : _c.data)) {
                            throw error_1.message;
                        }
                        if (Array.isArray((_e = (_d = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.errors) &&
                            Array.isArray((_g = (_f = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.warnings)) {
                            // if we only have warnings, allow overriding
                            if (((_k = (_j = (_h = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.warnings) === null || _k === void 0 ? void 0 : _k.length) &&
                                !((_o = (_m = (_l = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.errors) === null || _o === void 0 ? void 0 : _o.length)) {
                                throw error_1;
                            }
                        }
                        message = "";
                        // if we managed to get an error message from the server
                        if ((_r = (_q = (_p = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.message) === null || _r === void 0 ? void 0 : _r.non_field_errors) {
                            // if we got a django serializer non-field error, use that
                            message = (_u = (_t = (_s = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.message) === null || _u === void 0 ? void 0 : _u.non_field_errors;
                        }
                        else if ((_w = (_v = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.message) {
                            message = (_y = (_x = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.message;
                        }
                        else {
                            message = error_1.response.data;
                        }
                        // if csrf is invalid, clear it and try again
                        if (/csrf/i.test(message) && ((_z = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _z === void 0 ? void 0 : _z.status) !== 500) {
                            if (!attempts) {
                                throw error_1;
                            }
                            ApiHttpClass.csrfToken = null;
                            return [2 /*return*/, this.fetch(endpoint, options, payload, attempts - 1)];
                        }
                        // Handle AuthorizeNet expired OTS Token, so it doesn't conflict with token below.
                        if (/Invalid OTS Token/i.test(message)) {
                            throw new OtsTokenError("There was an error communicating to the secure payment gateway, please try again.");
                        }
                        // special handling for when your token is expired
                        if (/Token/i.test(message) && /(invalid|expired)/i.test(message)) {
                            if (!attempts) {
                                throw error_1;
                            }
                            // hit login endpoint to discard any http-only token, throw expired error
                            return [2 /*return*/, this.fetch("session", { apiUrl: apiUrl }, {}, attempts - 1)];
                        }
                        // special handling for when a submit is attempted on an incomplete application
                        if (((_0 = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _0 === void 0 ? void 0 : _0.data.id) === "application_incomplete") {
                            throw {
                                message: message,
                                id: error_1.response.data.id,
                                error: new Error(),
                            };
                        }
                        // special handling for when an application post triggers the existing user email verification
                        if (((_1 = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _1 === void 0 ? void 0 : _1.data.id) === "email_verification") {
                            throw {
                                message: message,
                                id: error_1.response.data.id,
                                error: new Error(),
                            };
                        }
                        // special handling for when an mfa code is requested
                        if ([
                            "two_factor_authentication_code_requested",
                            "two_factor_authentication_required",
                        ].includes(error_1.response.data.id)) {
                            throw {
                                message: message,
                                id: error_1.response.data.id,
                                seed: error_1.response.data.seed,
                                devices: error_1.response.data.devices,
                                device: error_1.response.data.device,
                                error: new Error(),
                            };
                        }
                        throw message;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ApiHttpClass.cullNullValues = function (payload) {
        // django serializers will return null values for all keys, ignore uninitialized keys
        var result = {};
        if (typeof payload === "string") {
            return payload;
        }
        if ([undefined, null].includes(payload)) {
            return;
        }
        Object.keys(payload).forEach(function (key) {
            if (payload[key] !== null) {
                if (Array.isArray(payload[key])) {
                    result[key] = payload[key].map(function (a) {
                        return ApiHttpClass.cullNullValues(a);
                    });
                }
                else if (typeof payload[key] === "object") {
                    result[key] = ApiHttpClass.cullNullValues(payload[key]);
                }
                else {
                    result[key] = payload[key];
                }
            }
        });
        return result;
    };
    ApiHttpClass.serialize = function (application, shouldSkipSerializeAmount) {
        if (application instanceof FormData) {
            return application;
        }
        var result = Utils.deepCopy(application);
        if (application.applicants && application.applicants.length) {
            result.applicants = application.applicants.map(function (applicant) {
                var resultApplicant = __assign(__assign({}, applicant), { date_of_birth: applicant.date_of_birth });
                if (resultApplicant.date_of_birth) {
                    resultApplicant.date_of_birth = new Date(resultApplicant.date_of_birth)
                        .toISOString()
                        .substr(0, 10);
                }
                return resultApplicant;
            });
            result.applicants[0].is_primary = true;
        }
        if (application.selected_products) {
            result.selected_products = application.selected_products.map(function (product) {
                var resultProduct = __assign(__assign({}, product), { amount: product.amount, minimum_balance: product.minimum_balance });
                if (!shouldSkipSerializeAmount) {
                    resultProduct.amount = Filters.dollarsToPennies(Number(product.amount));
                }
                resultProduct.minimum_balance = Filters.dollarsToPennies(product.minimum_balance);
                return resultProduct;
            });
        }
        if (application.funding) {
            result.funding = __assign(__assign({}, application.funding), { amount: Filters.dollarsToPennies(application.funding.amount) });
        }
        return __assign({}, result);
    };
    ApiHttpClass.deserialize = function (application) {
        if (application.applicants) {
            application.applicants.forEach(function (applicant) {
                if (applicant.date_of_birth) {
                    var options = {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        timeZone: "UTC",
                    };
                    applicant.date_of_birth = new Date(applicant.date_of_birth).toLocaleDateString("en-US", options);
                }
            });
        }
        if (application.selected_products) {
            application.selected_products.forEach(function (product) {
                product.amount = Filters.penniesToDollars(product.amount);
                product.minimum_balance = Filters.penniesToDollars(product.minimum_balance);
            });
        }
    };
    ApiHttpClass.csrfToken = null;
    return ApiHttpClass;
}());
export var fetchHttpClient = {
    request: function (_a) {
        var url = _a.url, method = _a.method, headers = _a.headers, data = _a.data, params = _a.params, withCredentials = _a.withCredentials;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                if (data instanceof FormData) {
                    // If the data is a FormData object, we don't want to set the Content-Type header
                    delete headers["Content-Type"];
                }
                return [2 /*return*/, customFetch(url, __assign({ method: method, headers: __assign({}, headers), credentials: withCredentials ? "include" : "same-origin" }, (REQUESTS_WITHOUT_BODY.includes(method)
                        ? {}
                        : {
                            body: data instanceof FormData ? data : JSON.stringify(data),
                        })), REQUESTS_WITHOUT_BODY.includes(method) ? __assign(__assign({}, data), params) : params)];
            });
        });
    },
};
// Using the ApiHttpClass class with fetch
var apiWithFetch = new ApiHttpClass(fetchHttpClient);
// This export is so that any type of HttpClient can be used with the ApiHttpClass class
export { ApiHttpClass };
export default apiWithFetch;
