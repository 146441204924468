import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Account from "byzantine/src/Account";
import { catcat, Portal, useBreakpoints } from "cerulean";

import TopMenu from "../TopMenu";
import BaseBanner from "../BaseBanner";
import ChartCard from "./ChartCard";
import ServicesCard from "./ServicesCard";
import TotalsCard from "./TotalsCard";
import AccountBalances from "./AccountBalances";
import { AccountContextProvider } from "../contexts/AccountContext";
import { UserFeaturesContextProvider } from "../contexts/UserFeaturesContext";
import RecentTransactionsCard from "./RecentTransactionsCard";
import MarketingCardContainer from "./MarketingCardContainer";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import MarketingImage from "./MarketingImage";

import style from "../DualColumnLayout.module.scss";

export default function Dashboard(props) {
  const { l10n } = useLocalization();
  const { institution, accounts, features, replyTime } = props;
  const { currentUser } = useCurrentUser();
  const userName = currentUser.org_name || currentUser.getShortDescription();
  const { apps } = institution;
  const { l } = useBreakpoints();
  const marketingCardImage = (
    <MarketingImage address="b3bbe7e6-f68e-45e3-8124-691e18a4a3ca" />
  );
  const marketingBannerImage = (
    <MarketingImage address="fb06f1b7-87ff-4fcf-8a98-51a7fb4ba36d" />
  );

  return (
    <UserFeaturesContextProvider>
      <AccountContextProvider accounts={accounts}>
        <Portal rootId="top-menu-react">
          <TopMenu {...props} />
        </Portal>
        <div className="dashboard-page-container">
          <BaseBanner
            bannerName={l10n.getString("dashboard-welcome", { userName })}
            buttonFunction={() => window.location.assign("/transfer/")}
            buttonName={l10n.getString(
              "button-transfer",
              null,
              "Transfer funds",
            )}
            orFeatureFlagChecks={["transfers"]}
            features={features}
          />
          <div className={style.pageLayout}>
            <div className={catcat(style.leftColumn, style.second)}>
              <AccountBalances />
              {!l && <MarketingCardContainer />}
              <RecentTransactionsCard replyTime={replyTime} />
              {l && marketingBannerImage}
            </div>
            <div className={catcat(style.rightColumn, style.first)}>
              <TotalsCard />
            </div>
            <div className={catcat(style.rightColumn, style.third)}>
              <ChartCard />
              {l && <MarketingCardContainer />}
              <ServicesCard services={apps} />
              {marketingCardImage}
            </div>
          </div>
        </div>
      </AccountContextProvider>
    </UserFeaturesContextProvider>
  );
}

Dashboard.propTypes = {
  institution: PropTypes.object.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.instanceOf(Account)),
  features: PropTypes.object,
  replyTime: PropTypes.string,
};
