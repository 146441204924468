// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--v5ZUg{margin:0 var(--space-l)}@media only screen and (min-width: 768px){.container--v5ZUg{width:645px;margin:auto}}@media only screen and (min-width: 1280px){.container--v5ZUg{width:1156px}}", "",{"version":3,"sources":["webpack://./components/settings/SettingsNavBar.module.scss","webpack://./../scss/_mixins.scss"],"names":[],"mappings":"AAEA,kBACE,uBAAA,CCYA,0CDbF,kBAII,WAAA,CACA,WAAA,CAAA,CCQF,2CDbF,kBASI,YAAA,CAAA","sourcesContent":["@import \"../../../scss/mixins\";\n\n.container {\n  margin: 0 var(--space-l);\n\n  @include minViewport(\"m\") {\n    width: 645px;\n    margin: auto;\n  }\n\n  @include minViewport(\"l\") {\n    width: 1156px;\n  }\n}","@use \"sass:map\";\n@import \"./vars\";\n\n/**\n* Wraps content in a media query that targets the\n* given breakpoint size _and larger_.\n*\n* ```scss\n* @include minViewport(\"m\") {\n*   // styles targeting \"m\" size _and larger_\n* }\n* ```\n*/\n@mixin minViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (min-width: #{$breakpoint}) {\n    @content;\n  }\n}\n\n/**\n* Wraps content in a media query that targets viewports\n* smaller than the given breakpoint size.\n* (Not inclusive of the given breakpoint)\n*\n* ```scss\n* @include maxViewport(\"m\") {\n*   // styles targeting viewports smaller than \"m\"\n* }\n* ```\n*/\n@mixin maxViewport($breakpointSize) {\n  $breakpoint: map-get($breakpoints-map, $breakpointSize);\n  @media only screen and (max-width: #{$breakpoint - 1px}) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--v5ZUg"
};
export default ___CSS_LOADER_EXPORT___;
